@mixin account-register {
	@at-root .account-register {
		&__business-info {
			&--hidden {
				display: none;

				&.list--usps {
					display: block;
				}
			}
		}

		&__consumer-info {
			&--hidden {
				display: none;
			}
		}

		@content;
	}
}
