@include nav {
	padding: 0 $block-spacing;

	&__grid {
		display: flex;
		flex-flow: column nowrap;
		width: 100%;
	}

	&__row-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		flex-flow: row nowrap;
		width: 100%;
		padding-top: $block-spacing * 2;
	}

	&__row-bot {
		display: flex;
		justify-content: space-between;
		align-items: stretch;
		flex-flow: row nowrap;
		width: 100%;
	}

	&__link {
		position: relative;
		display: flex;
		align-items: center;
		text-transform: uppercase;
	}

	&__items {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		justify-content: center;
		width: 100%;

		.nav__item {
			display: flex;
			align-items: stretch;
			justify-content: center;
			padding: 0 $block-spacing * 2;
		}

		.nav__link {
			padding: $block-spacing * 3 0;
		}
	}

	&__cart {
		background-color: $background-color;
		padding: $block-spacing * 1.5 $block-spacing * 2;
		margin-left: $block-spacing * 3;
		display: flex;
		align-items: center;
		border-radius: 50px;
		border-width: 0;
		color: $font-color;
		font-size: 16px;
		gap: $block-spacing * 2;
		font-weight: 600;

		&:hover {
			color: $black;
			cursor: pointer;
		}
	}

	&__item {
		font-size: 16px;
		line-height: 1.5em;
		font-weight: 600;
		color: $font-color;
		padding: 0;

		&:hover {
			text-decoration: none;

			.nav__sub {
				display: flex;
				align-items: flex-start;
			}
		}

		&--active {
			font-weight: bold;
			color: $brand-color;
		}

		&--mobile {
			display: none;
		}

		&--active,
		&:hover {
			.nav__link {
				&::after {
					content: ' ';
					display: block;
					position: absolute;
					bottom: -1px;
					left: 0;
					width: 100%;
					height: 5px;
					background-color: $brand-color;
				}
			}
		}

		&--relative {
			position: relative;
		}
	}

	&__icon {
		font-size: 24px;
		margin: 0;
		cursor: pointer;
	}

	&__count {
		position: absolute;
		top: -4px;
		right: -8px;
		min-width: 18px;
		height: 18px;
		border-radius: 18px;
		text-align: center;
		line-height: 18px;
		color: $font-color;
		font-size: 12px;
		font-weight: 700;
		padding: 0 2px;
		background-color: $highlight-color;
	}

	&__container {
		&--left {
			flex: 1 0 auto;
			width: 25%;
		}

		&--center {
			flex: 1 1 auto;
			flex-flow: column wrap;
			justify-content: space-between;
			align-items: center;
			width: 50%;
		}

		&--right {
			flex: 1 0 auto;
			// width: 25%;

			.nav__item {
				padding: 0 0 0 $block-spacing * 3;
				display: flex;
				align-items: center;

				&--mobile {
					display: none;
				}

				img {
					margin-right: $block-spacing;
				}

				&:hover {
					color: $black;
				}
			}

			.nav__cart {
				.nav__item {
					padding: 0;
				}
			}
		}
	}

	&__sub-container {
		width: $grid-width;
		margin: 0 auto;
		max-width: 100%;
		display: flex;
		padding: $block-spacing * 4 0;
	}

	&__sub-items {
		width: 100%;
		display: flex;
		align-items: flex-start;
		flex-direction: column;
		padding: 0 $block-spacing * 3;
		border-right: 1px solid $border-color;

		&--categories {
			width: 25%;
			border-right: 1px solid $border-color;
		}

		&:only-child {
			border: 0;
		}
	}

	&__banner {
		width: 100%;
		background-color: $brand-color;
		border-radius: 8px;
		padding: $block-spacing * 3;
		color: $white;
		height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
	}

	&__banner-image {
		display: block;
		max-width: 100%;
		border-radius: 8px;
		margin-bottom: $block-spacing * 3;
	}

	&__banner-title {
		font-size: 18px;
		line-height: 36px;
		font-weight: 700;
		text-transform: uppercase;
	}

	&__banner-content {
		font-size: 16px;
		line-height: normal;
		font-weight: 400;
		padding-bottom: $block-spacing * 2;
	}

	&__sub-item {
		display: block;
		font-size: 16px;
		color: $font-color;
		line-height: 32px;
		font-weight: 400;
		width: 100%;
		padding: 0 $block-spacing * 2;
		position: relative;
		cursor: pointer;

		@include responsive(('desktop', 'desktop-hd')) {
			&:hover,
			&--active {
				background-color: $background-color;
				border-radius: 8px;
				text-decoration: underline;
			}
		}

		&--heading {
			text-transform: uppercase;
			font-weight: 700;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			gap: $block-spacing * 2;
			padding-right: 0;

			&::after {
				content: ' ';
				display: block;
				height: 1px;
				flex: 1;
				background-color: $border-color;
			}

			&:hover {
				background-color: unset;
				border-radius: unset;
				text-decoration: unset;
			}
		}

		&--view-all {
			text-decoration: underline;
			font-weight: 700;

			&:hover {
				background-color: unset;
				border-radius: unset;
				text-decoration: unset;
			}
		}
	}

	&__sub-icon {
		position: absolute;
		top: 50%;
		right: $block-spacing;
		transform: translateY(-50%);
	}

	&__sub-inner {
		width: 25%;
		border-right: 1px solid $border-color;
		display: none;

		&--active {
			display: flex;
		}
	}

	&__sub-outer {
		width: 50%;
		display: flex;
		flex-flow: row wrap;
		margin-left: auto;

		.nav__sub-items {
			width: 50%;
		}
	}

	&__sub {
		background-color: $white;
		position: absolute;
		top: 100%;
		left: 50%;
		transform: translateX(-50%);
		right: 0;
		z-index: 9;
		display: none;
		align-items: center;
		justify-content: center;
		padding: 0;
		border-radius: 0 0 8px 8px;
		border: 1px solid $border-color;
		width: calc(#{$grid-width} - #{$block-spacing * 2});
		max-width: 100%;
		max-height: calc(100vh - 140px);
		overflow-y: auto;

		&--small {
			width: 328px;
			max-width: unset;
			transform: unset;
			right: auto;
			left: -$block-spacing * 4;
			display: none;
			padding: $block-spacing * 3 0;

			.nav__sub-items {
				width: 100%;
				padding-left: $block-spacing * 3;
				padding-right: $block-spacing * 3;
			}
		}
	}

	&__mobile-dropdown {
		display: none;
		position: fixed;
		z-index: 9;
		background-color: $white;
		top: 95px;
		left: 0;
		right: 0;
		bottom: 0;

		&--active {
			display: block;
		}
	}

	&__mobile-container {
		height: 100%;
		display: flex;
		flex-direction: column;

		&--hidden {
			display: none;
		}
	}

	&__mobile-scroll {
		flex: 1;
		overflow-y: auto;
	}

	&__mobile-header {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: $block-spacing * 3 $block-spacing * 4;
		position: relative;

		&::after {
			content: ' ';
			display: block;
			position: absolute;
			width: calc(100% - #{$block-spacing * 8});
			height: 1px;
			background-color: $border-color;
			top: 100%;
			left: $block-spacing * 4;
		}
	}

	&__mobile-title {
		font-size: 18px;
		color: $black;
		font-weight: bold;
		font-family: $secondary-font-family;
	}

	&__mobile-link {
		padding: $block-spacing * 2 $block-spacing * 4;
		flex: 1;
	}

	&__mobile-icon {
		padding: $block-spacing * 2 $block-spacing * 4;
		font-size: 18px;
	}

	&__mobile-item {
		font-size: 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: $font-color;
		font-weight: 400;
		font-family: $secondary-font-family;

		&:first-child {
			margin-top: $block-spacing * 2;
		}

		&--sub {
			font-size: 16px;
			font-family: $primary-font-family;
			font-weight: normal;

			.nav__mobile-link,
			.nav__mobile-icon {
				padding: $block-spacing * 2 $block-spacing * 4;
			}

			&:first-child {
				margin-top: $block-spacing * 2;
			}
		}

		&--active {
			font-weight: bold;
		}
	}

	&__mobile-toggle,
	&__mobile-close,
	&__mobile-back {
		display: flex;
		align-items: center;
		font-size: 24px;
		color: $black;
		padding-left: $block-spacing;
		height: 100%;
		cursor: pointer;

		span {
			margin-left: $block-spacing;
			font-size: 14px;
			text-transform: uppercase;
		}
	}

	&__mobile-toggle {
		padding: 0 $block-spacing * 2 0 $block-spacing;
	}

	&__mobile {
		display: none;
		align-items: center;
		justify-content: flex-start;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		&__grid {
			flex: 1;
		}

		&__sub-items {
			padding: 0 $block-spacing * 2;
		}

		&__mobile {
			display: flex;
		}

		&__item {
			&--desktop {
				display: none;
			}

			&--mobile {
				display: block;
			}
		}

		&__row-top {
			padding: 10px 0;
		}

		&__row-bot {
			display: none;
		}

		&__container--center {
			padding: 0;
		}

		&__cart {
			padding: $block-spacing * 1.5;
			min-height: 42px;
			min-width: 42px;
		}

		&__cart-price {
			display: none;
		}

		&__container--right {
			padding-right: $block-spacing;

			.nav__item {
				padding-left: $block-spacing * 2;

				img {
					margin-right: 0;
				}

				&--mobile {
					display: flex;
				}
			}

			.nav__mobile-hidden {
				display: none;
			}

			.nav__icon {
				font-size: 18px;
			}
		}
	}
}
