@mixin shoppingcart-total {
	@at-root .shoppingcart-total {
		@extend %flex-row;

		padding: #{$block-spacing * 2} 0;

		&__content {
			@extend %paragraph;

			display: inline-block;
			width: 70%;
			text-align: left;

			&--success {
				color: $success-color;
			}

			&--bold {
				font-weight: bold;
			}

			&--value {
				width: 30%;
				text-align: right;
			}

			&--spacing {
				margin-bottom: $block-spacing * 2;
			}

			&--full {
				width: 100%;
			}

			&--freeshipping {
				text-align: center;
				padding: $block-spacing * 2 $block-spacing;
				background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%2300BD71FF' stroke-width='3' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
				border-radius: 8px;
			}
		}

		&__to-zero-shipping-costs {
			font-weight: 500;
		}

		@content;
	}
}
