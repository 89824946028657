.category-banner {
	background-color: $brand-color;
	padding: $block-spacing * 4 $block-spacing * 4 $block-spacing * 3;
	border-radius: 8px;
	height: 100%;
	border: 1px solid $border-color;
	display: flex;
	flex-flow: column nowrap;
	gap: $block-spacing * 2;
	justify-content: space-between;

	&__top {
		display: flex;
		flex-flow: column nowrap;
		gap: $block-spacing;
	}

	.media {
		margin-bottom: $block-spacing * 2;
	}

	.button {
		width: 100%;
	}
}
