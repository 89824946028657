@mixin account-popup {
	@at-root .account-popup {
		display: block;
		font-size: 0;
		background-color: $white;
		max-width: 100%;
		max-height: 100%;
		position: fixed;
		left: 50%;
		top: 50%;
		transform: translateY(-50%) translateX(-50%);
		font-family: $primary-font-family;
		color: $font-color;
		z-index: 2;
		float: none;

		&__top {
			border-bottom: 1px solid $border-color;
			line-height: 50px;
			font-size: 13px;
			font-weight: 700;
			padding: 0 $block-spacing;
			text-align: center;
			text-transform: uppercase;

			@include responsive('mobile') {
				font-size: 11px;
			}
		}

		&__title {
			font-size: 13px;
			font-weight: 700;
			border-bottom: 1px solid $border-color;
			border-top: 1px solid $border-color;
			text-align: center;
			line-height: 50px;
			text-transform: uppercase;

			@include responsive('mobile') {
				font-size: 11px;
			}
		}

		&__content {
			color: $font-color;
			font-family: $primary-font-family;
			font-size: 12px;
			line-height: 17px;
		}

		&__star {
			color: $font-color;
			font-size: 20px;
			font-weight: 300;
			line-height: 21px;
		}

		&__close {
			color: $font-color;
			font-size: 13px;
			position: absolute;
			top: 0;
			right: 0;
			width: 50px;
			height: 50px;
			cursor: pointer;
		}

		&__scroll {
			padding: $block-spacing 0;
			height: 298px;
			overflow-y: scroll;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				height: auto;
				overflow: visible;
			}
		}

		&--hidden {
			display: none;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			padding: 0 $block-spacing $block-spacing;
			position: fixed;
			width: calc(100% - 24px);
			max-height: calc(100% - 24px);
			top: 50%;
			left: $block-spacing;
			transform: translateY(-50%) translateX(-0%);
			overflow-y: scroll;
			padding-top: 62px;

			&__top {
				position: fixed;
				top: 0;
				left: $block-spacing;
				width: calc(100% - 24px);
				z-index: 1;
				background: $white;
			}
		}

		@content;
	}
}
