.return-giftcard-message {
	padding: $block-spacing * 2;
	background-color: rgb(255 223 99 / 12%);
	line-height: 24px;
	background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FFDF63FF' stroke-width='4' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
	border-radius: 8px;
	color: $font-color;

	a {
		font-weight: 600;
		text-decoration: underline;
	}

	b {
		font-weight: bold;
	}
}
