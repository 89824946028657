@mixin overlay {
	@at-root .overlay {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;

		&--background {
			background-color: rgba($overlay-color, 0.5);
		}

		&--hero {
			background-color: rgba($brand-color, 0.25);
		}

		&--hover {
			opacity: 0;
			transition: opacity 0.5s ease-in-out;

			&:hover {
				opacity: 1;
			}
		}

		&--hidden {
			display: none;
		}

		&--fixed {
			position: fixed;
		}

		&--main {
			z-index: 9;
			position: fixed;
			background-color: rgba($brand-color, 0.1);
		}

		@content;
	}
}
