.table {
	font-size: 14px;
	color: $font-color;
	margin-bottom: $block-spacing * 4;
	min-width: 100%;

	&__tr {
		background-color: $background-color;

		&:nth-child(odd) {
			background-color: $white;
		}
	}

	&__td {
		padding: $block-spacing $block-spacing * 2;
	}

	a {
		color: $brand-color;
		text-decoration: underline;
	}

	i {
		color: $brand-color;
		margin-right: $block-spacing;
	}

	&__link {
		display: block;
		text-align: center;
		text-decoration: underline;
		color: $brand-color;
		margin-bottom: $block-spacing * 4;
		font-size: 14px;
	}

	&--2-cells {
		.table__td {
			width: 50%;
		}
	}

	&--4-cells {
		.table__td {
			width: 25%;
		}
	}
}
