@mixin size() {
	@at-root .size {
		@include replacement('size', false) {
			@extend %paragraph;

			border: 1px solid $border-color;
			background-color: $white;
			margin: 0 $block-spacing $block-spacing 0;
			padding: $block-spacing;
			min-width: 40px;
			text-align: center;

			&:hover,
			&--active {
				border: 1px solid $brand-color;
			}
		}

		@include replacement('size', true) {
			background-color: $black;
			color: $white;
		}

		&--hidden {
			display: none;
		}

		&--out-of-stock {
			.size__replacement {
				opacity: 0.3;
				pointer-events: none;
				text-decoration: line-through;
			}
		}

		@content;
	}
}
