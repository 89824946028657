@include guide {
	font-size: 12px;
	line-height: 1.5em;
	color: $font-color;
	justify-content: space-between;
	padding: $block-spacing * 4 $block-spacing 0;
	width: 100%;
	overflow-x: auto;
	white-space: nowrap;

	&__container {
		@extend %flex-row;

		gap: $block-spacing;
		align-items: center;
		flex-flow: row nowrap;

		&:first-child {
			justify-content: flex-start;
		}

		&:last-child {
			justify-content: flex-end;
		}
	}

	&__item {
		@extend %flex-row;

		align-items: center;
		padding: 0;

		&:hover {
			text-decoration: none;
			color: $black;
		}

		&:last-child {
			font-weight: 700;
		}
	}

	&__icon {
		margin: 2px 0 0;
	}

	&--product,
	&--article {
		padding: $block-spacing * 4 $block-spacing;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			padding: $block-spacing * 4 $block-spacing 0;
		}
	}

	&--calendar {
		padding: $block-spacing * 4 $block-spacing;
	}
}
