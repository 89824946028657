@mixin title {
	@at-root .title {
		color: $font-color;
		font-family: $secondary-font-family;
		font-size: 40px;
		line-height: normal;

		&--h2 {
			font-size: 40px;
		}

		&--h3 {
			font-size: 40px;
		}

		&--h4 {
			font-size: 32px;
		}

		&--h5 {
			font-size: 24px;
		}

		&--h6 {
			font-size: 18px;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--bold {
			font-weight: bold;
		}

		&--align-left {
			text-align: left;
		}

		&--align-center {
			text-align: center;
		}

		&--white {
			color: $white;
		}

		&--spacing {
			margin-top: $block-spacing;
			margin-bottom: $block-spacing;
		}

		&--spacing-2x {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-3x {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-4x {
			margin-top: $block-spacing * 4;
			margin-bottom: $block-spacing * 4;
		}

		&--spacing-top {
			margin-top: $block-spacing;
		}

		&--spacing-top-2x {
			margin-top: $block-spacing * 2;
		}

		&--spacing-top-3x {
			margin-top: $block-spacing * 3;
		}

		&--spacing-top-4x {
			margin-top: $block-spacing * 4;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		&--spacing-bottom-2x {
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-bottom-4x {
			margin-bottom: $block-spacing * 4;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			font-size: 24px;

			&--h2 {
				font-size: 24px;
			}

			&--h3 {
				font-size: 24px;
			}

			&--h4 {
				font-size: 24px;
			}

			&--h5 {
				font-size: 18px;
			}

			&--h6 {
				font-size: 18px;
			}

			&--spacing {
				margin-top: $block-spacing * 2;
				margin-bottom: $block-spacing * 2;
			}

			&--spacing-top {
				margin-top: $block-spacing * 2;
			}

			&--spacing-bottom {
				margin-bottom: $block-spacing * 2;
			}

			&--align-center-tablet {
				text-align: center;
			}
		}

		@content;
	}
}
