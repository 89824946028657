@include footer {
	background-color: $background-color-2;
	align-items: center;
	justify-content: center;
	padding: 0;
	margin: 0;

	&__top {
		width: 100%;
		background-color: $background-color-3;
		min-height: 140px;
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing * 3;
		align-items: center;
		justify-content: center;
		color: $white;
		font-weight: 700;
		font-size: 24px;
		line-height: 1.5em;
		position: relative;

		&::before {
			content: ' ';
			position: absolute;
			bottom: 100%;
			left: 0;
			right: 0;
			width: 100%;
			height: 27px;
			background-image: url('/_dekroo/img/top-bg-3.svg');
			background-position: center top;
		}

		i {
			font-size: 32px;
		}

		a {
			&:hover {
				border-bottom: 1px solid $white;
			}
		}
	}

	&__mid {
		width: 100%;
		background-image: url('/_dekroo/img/dkr.png');
		background-position: top right 12%;
		background-repeat: no-repeat;
		min-height: 550px;
		background-size: 550px;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			background-image: unset;
			min-height: unset;
			margin-bottom: 0;
			padding-top: 0;
		}
	}

	&__container {
		width: 100%;
		padding: $block-spacing * 8 0;
		border-bottom: 1px solid $border-color;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			padding: 0;
			border: 0;
		}
	}

	&__bot {
		@extend %flex-row;

		align-items: center;
		width: 100%;
		border-bottom: 1px solid $border-color;
		padding: 0 0 $block-spacing * 2;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			border-top: 0;
			padding-top: 0;
		}
	}
}
