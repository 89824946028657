@mixin nav {
	@at-root .nav {
		@extend %flex-row;

		flex-grow: 1;
		align-items: center;
		justify-content: space-between;
		padding: calc(#{$block-spacing} / 2);

		&__container {
			@extend %flex-row;

			align-items: center;

			&--left {
				justify-content: flex-start;
			}

			&--right {
				justify-content: flex-end;
			}
		}

		&__item {
			padding: calc(#{$block-spacing} / 2);

			&--active {
				font-weight: bold;
			}

			&:hover {
				text-decoration: underline;
			}
		}

		&__icon {
			margin-right: $block-spacing;
		}

		@content;
	}
}
