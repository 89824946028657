.product-review {
	color: $font-color;

	&__form {
		border-bottom: 1px solid $border-color;
	}

	&__rating {
		font-size: 16px;
		font-weight: 700;
	}

	&__stars {
		color: $highlight-color;
		font-size: 16px;
		display: flex;
		align-items: center;
	}

	&__star {
		cursor: pointer;

		// &:hover,
		//Need to use important because of lint "no-descending-specificity"
		&--active {
			i {
				&:first-child {
					display: none !important;
				}

				&:last-child {
					display: inline-block !important;
				}
			}
		}

		i:first-child {
			display: inline-block;
		}

		i:last-child {
			display: none;
		}
	}

	&__label {
		font-size: 16px;
		font-weight: 700;
	}

	&__input {
		@extend %input;

		width: 100%;
		resize: none;
		border-radius: $block-spacing * 4;
		background-color: $white;
		border: 1px solid $border-color;
		padding: 15px;
		font-size: 14px;

		&--textarea {
			height: 222px;
			border-radius: $block-spacing * 2;
		}
	}

	&__name {
		font-size: 16px;
		font-weight: 700;
	}

	&__date {
		font-size: 14px;
		font-style: italic;
		flex: 1;
		text-align: right;
	}

	&__item {
		display: none;
		border-bottom: 1px solid $border-color;
		padding: $block-spacing * 3 0;

		&--active {
			display: block;
		}
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing * 2;
		padding-bottom: $block-spacing * 2;
		align-items: flex-end;
	}

	&__col {
		display: flex;
		flex: 1;
		width: 50%;
		flex-flow: column wrap;
		gap: $block-spacing * 2;

		&:last-child {
			.text {
				text-align: right;
			}
		}
	}

	&__read-more {
		text-align: center;
		justify-content: center;
		align-items: center;
		display: flex;
		flex-flow: row wrap;
		padding: $block-spacing * 3;
		text-decoration: underline;
		color: $black;
		font-size: 16px;
		cursor: pointer;
	}

	&__pages {
		display: flex;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		padding: $block-spacing * 3 0;
	}

	&__page,
	&__page-button {
		display: block;
		height: 30px;
		width: 30px;
		color: $black;
		line-height: 30px;
		text-align: center;
		font-size: 14px;
		background-color: rgba($highlight-color, 0.1);
		cursor: pointer;

		&:hover,
		&--active {
			background-color: rgba($highlight-color, 1);
			color: $white;
			font-weight: bold;
		}

		&--hidden {
			display: none;
		}
	}
}
