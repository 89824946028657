@mixin color() {
	@at-root .color {
		@include replacement('color', false) {
			border: 5px solid $white;
			box-shadow: 0 0 0 1px $border-color;
			background-color: $white;
			margin: 1px $block-spacing $block-spacing 1px;
			height: 31px;
			width: 31px;
			border-radius: 31px;

			&:hover {
				box-shadow: 0 0 0 1px $black;
			}
		}

		@include replacement('color', true) {
			box-shadow: 0 0 0 1px $black;
		}

		&--hidden {
			display: none;
		}

		&--disabled {
			.color__replacement {
				opacity: 0.3;
				pointer-events: none;
				text-decoration: line-through;
			}
		}

		@content;
	}
}
