@mixin account-table {
	@at-root .account-table {
		width: 100%;
		overflow-x: auto;

		&__table {
			white-space: nowrap;
			width: 100%;
		}

		&__body {
			overflow-x: auto;
			max-width: 100%;
		}

		&__select {
			border: 1px solid $black;
			padding: $block-spacing;
			border-radius: 3px;
			font-family: $primary-font-family;
			color: $black;
			margin: $block-spacing 0;
			cursor: pointer;
		}

		&__row {
			.account-table__cell {
				border-bottom: 1px solid $border-color;
			}

			&--hidden {
				display: none;
			}

			&:last-child {
				.account-table__cell {
					border: 0;
				}
			}
		}

		&__cell,
		&__head {
			text-align: left;
			padding: $block-spacing 0;
			vertical-align: middle;

			&--align-right {
				text-align: right;
			}
		}

		&__head {
			@extend %heading6;

			font-weight: bold;
			padding-left: $block-spacing;

			&:first-child {
				padding-left: 0;
			}
		}

		&__cell {
			@extend %paragraph;

			padding-left: $block-spacing;

			&:first-child {
				padding-left: 0;
			}

			&--success,
			&--warning,
			&--error {
				position: relative;
				padding-left: calc(#{$block-spacing} + 10px);

				&::before {
					@extend %position-left;

					display: block;
					height: 10px;
					width: 10px;
					border-radius: 10px;
					content: ' ';
				}
			}

			&--success {
				&::before {
					background-color: $success-color;
				}
			}

			&--warning {
				&::before {
					background-color: $warning-color;
				}
			}

			&--error {
				&::before {
					background-color: $error-color;
				}
			}
		}

		&__link {
			font-weight: bold;

			&:hover {
				text-decoration: none;
				color: $hover-color;
			}
		}

		&__image-placeholder {
			position: relative;
			width: 100px;

			&::after {
				display: block;
				content: ' ';
				padding-bottom: 150%;
			}
		}

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;
		}

		&__delete {
			@extend %button;

			padding: $block-spacing;
		}

		&__reason {
			width: 40% !important;
			padding-right: 1px;
			margin-left: auto;
		}

		.mdc-select {
			min-width: 200px;
		}

		&--total {
			width: 50%;
			margin-left: auto;

			@include responsive('mobile') {
				width: 100%;
			}
		}

		@content;
	}
}
