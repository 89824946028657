.price-filter {
	width: 100%;
	display: flex;
	flex-direction: column;
	gap: $block-spacing * 2;

	&__container {
		display: flex;
		gap: $block-spacing;
		margin-top: $block-spacing;
	}

	&__input {
		height: $block-spacing * 6;
		border: 1px solid $border-color;
		border-radius: 99999999px;
		text-align: center;
		width: 50%;
		transition: border 0.3s ease-in-out 0s;

		&:hover {
			border: 1px solid $brand-color;
		}
	}
}
