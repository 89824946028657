@mixin product {
	@at-root .product {
		display: block;
		position: relative;
		border: 1px solid $border-color;
		height: 100%;
		width: 100%;

		&__image-placeholder {
			position: relative;
			display: block;
			width: 100%;
			height: auto;

			&::after {
				display: block;
				padding-bottom: 100%;
				content: ' ';
			}

			&--portrait {
				&::after {
					padding-bottom: 125%;
				}
			}

			&--landscape {
				&::after {
					padding-bottom: 75%;
				}
			}
		}

		&__image {
			@extend %position-center;

			max-width: 100%;
			max-height: 100%;
			display: block;
			width: auto;
		}

		&__info {
			padding: $block-spacing;
		}

		&__tag {
			background-color: $highlight-color;
			padding: $block-spacing;
			color: $white;
		}

		&__name {
			@extend %heading6;
		}

		&__price {
			@extend %flex-row;

			align-items: center;
			justify-content: space-between;

			&__advice {
				text-decoration: line-through;
			}

			&__highlight {
				color: $highlight-color;
			}
		}

		&__wishlist {
			@extend %position-top-right;

			color: $highlight-color;
			font-size: 24px;
		}

		@content;
	}
}
