.news {
	width: 100%;
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	justify-content: flex-start;

	.news-item {
		width: calc(25% - #{$block-spacing * 2});
		margin: $block-spacing;
		height: auto;

		@include responsive(('desktop')) {
			width: calc(33.33% - #{$block-spacing * 2});
		}

		@include responsive(('mobile-landscape', 'tablet', 'tablet-landscape')) {
			width: calc(50% - #{$block-spacing * 2});
		}

		@include responsive(('mobile')) {
			width: calc(100% - #{$block-spacing * 2});
		}
	}
}
