.set-info {
	background-color: $white;

	&__tags {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: $block-spacing;
		padding-bottom: $block-spacing * 2;
	}

	&__tag {
		background-color: $black;
		color: $white;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 6px 7px;
		border-radius: 50px;

		&--1 {
			background-color: $highlight-color-2;
		}

		&--2 {
			background-color: $background-color-3;
		}

		&--3 {
			background-color: $black;
		}
	}

	&__summary {
		font-size: 16px;
	}

	&__name {
		font-size: 32px;
		font-weight: 700;
		line-height: 1.5;
		margin-bottom: $block-spacing;
	}

	&__price {
		@extend %flex-row;

		margin-bottom: $block-spacing * 2;
		align-items: center;
		justify-content: space-between;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
	}

	&__price-highlight {
		font-size: 24px;
		font-weight: 700;
		color: $brand-color-2;
	}

	&__price-advice {
		font-size: 18px;
		color: $font-color-2;
		text-decoration: line-through;
	}

	&__price-container {
		display: flex;
		align-items: center;
		gap: $block-spacing;
	}

	&__price-save {
		font-size: 14px;
		color: $font-color;

		i {
			margin-right: 6px;
		}
	}

	&__container {
		@extend %flex-row;

		flex: 1 1;
		justify-content: space-between;
		align-items: center;
		padding-bottom: $block-spacing * 2;
	}

	&__product {
		display: flex;
		flex-direction: column;
		margin-top: $block-spacing * 2;
	}

	&__product-image {
		width: 80px;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;

		&::after {
			padding-bottom: 140%;
			content: ' ';
			display: block;
		}
	}

	&__product-info {
		flex: 1;
		padding-left: $block-spacing * 2;

		.set-info__price {
			border-bottom: 0;
			margin: 0;
		}
	}

	&__brand-name,
	&__category-name {
		font-size: 16px;
		font-weight: 400;
		text-decoration: underline;
		margin-bottom: $block-spacing;
	}

	&__product-name {
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;
		margin-bottom: $block-spacing;
	}

	&__product-price {
		font-size: 16px;
		font-weight: 700;
	}

	&__product-bottom {
		display: flex;
		flex-flow: row wrap;
		gap: $block-spacing * 2;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
	}

	.size-select {
		flex: 1 1 auto;
		width: auto;
	}

	.size-select__dropdown {
		margin-bottom: 0;
	}

	&__usp {
		display: flex;
		gap: $block-spacing * 2;
		flex-flow: row wrap;
		font-size: 14px;
		color: $font-color;
		padding-top: $block-spacing * 3;

		i {
			color: $brand-color-2;
			margin-right: $block-spacing;
			min-width: 18px;
		}

		&--no-border {
			border-top: 0;
			padding-top: 0;
		}
	}

	&__usp-item {
		width: 100%;
	}
}
