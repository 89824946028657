@include header {
	background-color: $white;
	padding: 0;
	position: relative;
	border: 0;

	&__fixed {
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 9;
		background-color: $white;
		border-bottom: 1px solid $border-color;
	}

	&__top {
		background-color: $brand-color;
		padding: $block-spacing / 2 0;
	}

	&__top,
	&__mid {
		@extend %flex-row;

		align-items: center;
		justify-content: center;
		width: 100%;
		padding: 0 5%;
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		width: 100%;
		max-width: $grid-width;
	}

	&__message {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		background-color: $highlight-color;
		color: $black;
		font-size: 14px;
		text-transform: uppercase;
		font-weight: 700;
		padding: $block-spacing 30px;
		position: relative;
		text-align: center;

		&--hidden {
			display: none;
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			font-size: 12px;
		}
	}

	&__message-close {
		@extend %button;

		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		font-size: 14px;
		padding: $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		&__fixed {
			min-height: unset;
		}

		&__top {
			padding: 0;
		}

		&__mid {
			display: flex;
			align-items: stretch;
		}

		&__top,
		&__mid {
			padding: 0;
		}

		&__container {
			flex: 1;
			display: flex;
			align-items: stretch;
		}
	}
}
