.size-guide {
	&__open {
		position: relative;
		padding-left: 20px;

		&::before {
			position: absolute;
			left: $block-spacing;
			display: inline-block;
			content: ' ';
			width: 1px;
			height: 17px;
			background-color: $font-color;
		}
	}

	&__scroll {
		overflow-y: auto;
		height: 100%;
	}

	&__dropdown {
		position: fixed;
		top: 50%;
		left: 50%;
		transform: translate(-50%, -50%);
		width: 890px;
		max-height: 100vh;
		max-width: 100vw;
		background-color: $white;
		z-index: 9;
		border: 1px solid $border-color;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
	}

	&__close {
		font-size: 18px;
		color: $black;
		top: $block-spacing * 2;
		right: $block-spacing * 2;
		cursor: pointer;
		position: absolute;
	}

	&__title {
		font-size: 18px;
		text-transform: uppercase;
		font-weight: 700;
		font-family: $primary-font-family;
		padding: $block-spacing * 2;
		color: $font-color;
	}

	&__heading {
		font-size: 16px;
		color: $black;
		font-weight: bold;
		padding: 0 $block-spacing * 2;

		i {
			margin-right: 12px;
		}
	}

	&__content {
		font-size: 16px;
		line-height: 1.5em;
		color: $font-color;
		flex: 1;
		padding: 0 $block-spacing * 2 $block-spacing * 2;

		strong,
		b {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ol {
			@extend %ordered-list;
		}

		ul {
			@extend %unordered-list;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		iframe {
			max-width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $secondary-font-family;
			line-height: normal;
			font-size: 24px;
		}

		td,
		th {
			text-align: left;
		}

		a {
			text-decoration: underline;
			color: $font-color;

			&:hover {
				text-decoration: underline;
			}
		}

		table {
			width: 100%;
			font-size: 12px;
			border-top: 1px solid $black;
			border-left: 1px solid $black;
			margin-top: $block-spacing * 2;

			th {
				font-weight: bold;
			}

			td,
			th {
				border-right: 1px solid $black;
				border-bottom: 1px solid $black;
				padding: $block-spacing / 2;

				&:first-child {
					font-weight: bold;
				}
			}
		}
	}

	&__overlay {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($black, 0.4);
		z-index: 9;
	}

	&--hidden {
		.size-guide__overlay,
		.size-guide__dropdown {
			display: none;
		}
	}
}
