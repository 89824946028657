@include product-images {
	@extend %flex-row;

	position: relative;

	&__image {
		display: flex;
		align-items: center;
		justify-content: center;
		width: 100%;
		position: relative;

		&::after {
			display: block;
			content: ' ';
			padding-bottom: 88%;
		}

		img {
			max-width: 80%;
			max-height: 80%;
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
		}
	}

	&__video {
		overflow: hidden;
		height: 100%;
		width: 100%;
		position: relative;

		iframe {
			@extend %position-top;

			width: 100%;
			height: 100%;
		}

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 56.25%;
		}
	}

	&__bullets {
		bottom: $block-spacing * 4;
		z-index: 1;
		width: 100%;
		display: flex;
		align-items: center;
		flex-flow: row nowrap;
		padding: $block-spacing 0;

		@include responsive(('mobile')) {
			padding: $block-spacing $block-spacing / 2;
		}

		i {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			font-size: 26px;
			color: $highlight-color-2;
		}
	}

	&__bullet {
		padding: 0;
		border: 1px solid $border-color;
		border-radius: 16px;
		cursor: pointer;
		width: calc(16.66% - #{$block-spacing * 2});
		margin: $block-spacing;

		&--background {
			background-position: center;
			background-size: contain;
			background-repeat: no-repeat;
			position: relative;

			&::after {
				content: ' ';
				padding-bottom: 88%;
				display: block;
			}
		}

		@include responsive(('mobile')) {
			border-radius: 8px;
			width: calc(16.66% - #{$block-spacing});
			margin: $block-spacing / 2;
		}
	}

	&__container {
		@extend %flex-row;

		align-items: flex-start;
		justify-content: flex-start;
		flex: 1;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}

	&__carousel {
		display: block;
		border: 1px solid $border-color;
		border-radius: 16px;
		width: calc(100% - #{$block-spacing * 2});
	}

	&__tags {
		@extend %flex-row;

		gap: $block-spacing;
		position: absolute;
		z-index: 99;
		top: 5%;
		left: 5%;

		&--bottomright {
			top: unset;
			left: unset;
			bottom: 5%;
			right: 5%;
		}
	}

	&__tag {
		font-size: 12px;
		font-weight: 700;
		color: $white;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 24px;
		padding: 0 $block-spacing;
		text-transform: uppercase;
		border-radius: 24px;

		&--bg {
			background-color: $highlight-color-2;
		}

		&--image {
			width: 100px;
			height: auto;
		}
	}
}
