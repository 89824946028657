@include product {
	padding: $block-spacing * 3 $block-spacing * 4;
	background-color: $white;
	border: 1px solid $border-color;
	border-radius: 8px;
	overflow: hidden;
	display: flex;
	flex-flow: column wrap;

	&__info {
		width: 100%;
		display: flex;
		flex-flow: column wrap;
		padding: 0;
		flex: 1 1 auto;
		justify-content: space-between;
	}

	&__name {
		font-size: 16px;
		color: $font-color;
		text-decoration: underline;
		display: block;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding-top: $block-spacing;
	}

	&__summary {
		font-size: 16px;
		line-height: 1.5em;
		color: $font-color;
		font-weight: 700;
		text-transform: uppercase;
		padding: $block-spacing 0;

		&--ellipsis {
			white-space: nowrap;
			overflow: hidden;
			text-overflow: ellipsis;
		}
	}

	&__message {
		font-size: 14px;
		color: $brand-color-2;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		margin-bottom: $block-spacing * 2;

		i {
			margin-right: $block-spacing;
			font-size: 12px;
		}
	}

	&__top {
		width: 100%;
		display: flex;
		flex-flow: row nowrap;
		align-items: top;
		justify-content: space-between;
		padding-bottom: $block-spacing * 1.5;
		gap: $block-spacing;
	}

	&__tags {
		@extend %flex-row;

		gap: $block-spacing;
	}

	&__tag {
		font-size: 12px;
		font-weight: 700;
		color: $white;
		background-color: $highlight-color-2;
		display: flex;
		align-items: center;
		justify-content: center;
		min-height: 24px;
		padding: 0 $block-spacing;
		text-transform: uppercase;
		border-radius: 24px;

		&--bg3 {
			background-color: $background-color-3;
		}

		&--black {
			background-color: $black;
		}
	}

	&__image-placeholder {
		margin-bottom: 0;

		&::after {
			padding-bottom: 80%;
		}
	}

	&__price {
		justify-content: flex-start;
		border-top: 1px solid $border-color;
		padding-top: $block-spacing * 2;
		gap: $block-spacing;

		&__advice {
			font-size: 16px;
			color: $font-color-2;
			text-decoration: line-through;
		}

		&__highlight {
			color: $font-color;
			font-size: 16px;
			font-weight: 700;
		}

		&__message {
			font-size: 12px;
			color: $font-color;
			font-weight: normal;
			width: 100%;
		}
	}

	&__colors {
		@extend %flex-row;

		align-items: center;
		gap: $block-spacing / 2;
		margin-bottom: $block-spacing * 2;
	}

	&__color {
		cursor: pointer;
		position: relative;
	}

	&__color-code {
		display: block;
		width: 18px;
		height: 18px;
		border: 1px solid $border-color;
		border-radius: 18px;
		margin: 3px;
	}

	&__color-replacement {
		display: block;
		width: 26px;
		height: 26px;
		border-radius: 26px;
		border: 1px solid transparent;
	}

	&__color-input {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		z-index: -999999999;

		&:hover + .product__color-replacement {
			border: 1px solid rgba($brand-color, 0.1);
		}

		&:checked + .product__color-replacement {
			border: 1px solid $brand-color;
		}
	}

	&__sizes {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;
		border-top: 1px solid $border-color;
		padding-top: $block-spacing * 2;
		gap: $block-spacing;
		padding-right: 56px;
		min-height: 58px;

		&--hidden {
			display: none;
		}

		&__message {
			font-size: 12px;
			color: $font-color;
			font-weight: normal;
			width: 100%;
		}
	}

	&__size {
		cursor: pointer;
		position: relative;

		&--disabled {
			pointer-events: none;
			color: red;
		}

		&--hidden {
			display: none;
		}
	}

	&__size-replacement {
		color: $font-color;
		font-size: 16px;
		font-weight: 700;
		text-transform: uppercase;

		&:hover {
			text-decoration: underline;
		}

		&--overflow {
			color: $font-color-2;

			&--hidden {
				display: none;
			}
		}
	}

	&__size-input {
		position: absolute;
		bottom: 0;
		left: 0;
		right: 0;
		opacity: 0;
		z-index: -999999999;

		&:checked + .product__size-replacement {
			color: $brand-color-2;
		}

		&:disabled + .product__size-replacement {
			user-select: none;
			pointer-events: none;
			color: $font-color-3;
		}
	}

	&__atb {
		@extend %button;

		background-color: $background-color;
		color: $font-color;
		font-size: 18px;
		height: 44px;
		width: 48px;
		border-radius: 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		right: 0;
		bottom: 0;
		transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;

		&:hover {
			background-color: $brand-color-2;
			color: $white;
		}

		&:disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	&__atb-plus {
		background-color: $highlight-color;
		color: $font-color;
		font-size: 12px;
		width: 18px;
		height: 18px;
		border-radius: 18px;
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 6px;
		right: 4px;

		&--hidden {
			display: none !important;
		}
	}

	&__atb-shopping--hidden {
		display: none !important;
	}

	&__info-top {
		width: 100%;
	}

	&__info-bottom {
		position: relative;

		&--hidden {
			opacity: 0;
			background-color: $white;
			position: absolute;
			bottom: -58px;
			right: $block-spacing * 4;
			left: $block-spacing * 4;
			transition: opacity 0.3s ease-in-out 0s, bottom 0.3s ease-in-out 0s;
		}
	}

	&__loader {
		display: block !important;

		&--hidden {
			display: none !important;
		}
	}

	&:hover {
		.product__info-bottom--hidden {
			opacity: 1;
			bottom: 19px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		padding: $block-spacing * 3 $block-spacing * 2;

		&__info-bottom {
			&--hidden {
				right: $block-spacing * 2;
				left: $block-spacing * 2;
			}
		}
	}
}
