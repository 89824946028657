.accordion {
	&:not(:last-child) {
		border-bottom: 1px solid $border-color;
	}

	&__toggle {
		cursor: pointer;
		position: relative;
	}

	&__icon-close,
	&__icon-open {
		@extend %position-right;
	}

	&__icon-close {
		display: block;
	}

	&__icon-open {
		display: none;
	}

	&__container {
		overflow-y: hidden;
		transition: height 0.3s ease-in-out 0s;
	}

	&--border {
		border: 1px solid $border-color;
		border-bottom: 0;
		padding: $block-spacing;

		&:last-child {
			border-bottom: 1px solid $border-color;
		}
	}

	&--hidden {
		.accordion__container {
			height: 0;
		}

		.accordion__icon-close {
			display: none;
		}

		.accordion__icon-open {
			display: block;
		}
	}

	&__sub {
		padding: $block-spacing 0;
	}
}
