@mixin account-pagination {
	@at-root .account-pagination {
		line-height: 25px;
		width: 100%;

		&__content {
			color: $font-color;
			font-family: $primary-font-family;
			font-size: 12px;
			display: inline-block;
			text-align: left;
			width: 100%;

			strong {
				font-weight: 700;
			}

			&--align-center {
				text-align: center;
			}

			&--align-right {
				text-align: right;
			}
		}

		&__nav {
			font-size: 0;

			&__pages {
				color: $font-color;
				font-family: $primary-font-family;
				font-size: 12px;
				display: inline-block;
				text-align: left;

				strong {
					font-weight: 700;
				}
			}

			&__prev,
			&__next {
				height: 25px;
				width: 25px;
				line-height: 25px;
				text-align: center;
				border-radius: 5px;
				background-color: $border-color;
				box-shadow: 0 0 4px 0 $border-color;
				color: $white;
				font-size: 13px;
				margin-left: $block-spacing;
				display: inline-block;
				cursor: pointer;
				transition: all 0.2s ease-in-out 0s;

				&:hover {
					background-color: $brand-color;
				}
			}

			&--align-right {
				text-align: right;
			}
		}

		@content;
	}
}
