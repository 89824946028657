@mixin footer {
	@at-root .footer {
		@extend %flex-row;

		align-items: flex-start;
		justify-content: space-between;
		width: 100%;
		padding: $block-spacing 0;
		border-top: 1px solid $border-color;
		margin-top: $block-spacing;

		@include responsive(('tablet', 'tablet-landscape', 'desktop', 'desktop-hd')) {
			&--small-device {
				display: none;
			}
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			&--large-device {
				display: none;
			}
		}

		@content;
	}
}
