@mixin text {
	@at-root .text {
		font-size: 16px;
		line-height: 1.5em;
		position: relative;
		width: 100%;
		color: $font-color;

		strong,
		b {
			font-weight: bold;
		}

		em {
			font-style: italic;
		}

		ol {
			@extend %ordered-list;
		}

		ul {
			@extend %unordered-list;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}

		iframe {
			max-width: 100%;
		}

		h1,
		h2,
		h3,
		h4,
		h5,
		h6 {
			font-family: $secondary-font-family;
			line-height: normal;
			font-size: 24px;
		}

		td,
		th {
			text-align: left;
		}

		a {
			text-decoration: underline;
			color: inherit;

			&:hover {
				text-decoration: underline;
			}
		}

		.button {
			text-decoration: unset;
			margin-top: $block-spacing;
		}

		&--white {
			color: $white;
		}

		&--black {
			color: $black;
		}

		&--align-left {
			text-align: left;
		}

		&--align-right {
			text-align: right;
		}

		&--align-center {
			text-align: center;
		}

		&--bold {
			font-weight: bold;
		}

		&--uppercase {
			text-transform: uppercase;
		}

		&--large {
			font-size: 18px;
			line-height: 36px;
		}

		&--small {
			font-size: 14px;
		}

		&--spacing {
			margin-top: $block-spacing;
			margin-bottom: $block-spacing;
		}

		&--spacing-top {
			margin-top: $block-spacing;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing;
		}

		&--spacing-2x {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top-2x {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom-2x {
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-3x {
			margin-top: $block-spacing * 3;
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-top-3x {
			margin-top: $block-spacing * 3;
		}

		&--spacing-bottom-3x {
			margin-bottom: $block-spacing * 3;
		}

		&--spacing-4x {
			margin-top: $block-spacing * 4;
			margin-bottom: $block-spacing * 4;
		}

		&--spacing-top-4x {
			margin-top: $block-spacing * 4;
		}

		&--spacing-bottom-4x {
			margin-bottom: $block-spacing * 4;
		}

		&--message {
			width: calc(100% - #{$block-spacing * 2});
			margin: $block-spacing;
			padding: $block-spacing * 2;
			background-color: $background-color;
			border-radius: 4px;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			h1,
			h2,
			h3 {
				font-size: 24px;
			}

			&--h4 {
				font-size: 24px;
			}

			&--h5 {
				font-size: 18px;
			}

			&--h6 {
				font-size: 18px;
			}

			&--spacing {
				margin-top: $block-spacing * 2;
				margin-bottom: $block-spacing * 2;
			}

			&--spacing-top {
				margin-top: $block-spacing * 2;
			}

			&--spacing-bottom {
				margin-bottom: $block-spacing * 2;
			}
		}

		@content;
	}
}
