@mixin sorting {
	@at-root .sorting {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		width: 100%;

		&__container {
			@extend %flex-row;

			justify-content: flex-start;
			align-items: center;
			flex: 1 1 auto;

			~ .sorting__container {
				justify-content: flex-end;
			}
		}

		&__content {
			@extend %paragraph;

			padding: $block-spacing #{$block-spacing / 2};
		}

		&__select {
			@extend %select;
			@extend %paragraph;

			padding: $block-spacing #{$block-spacing * 3} $block-spacing $block-spacing;
			border: 1px solid $border-color;
			background: url('#{$image-path}/icons/angle-down.png') no-repeat center right 8px;
			background-size: 12px;
		}

		@content;
	}
}
