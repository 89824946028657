.social {
	@extend %flex-row;

	align-items: center;
	justify-content: flex-end;
	width: 25%;
	gap: $block-spacing;
	margin: $block-spacing * 2 0;

	&__item {
		display: flex;
		align-items: center;
		justify-content: center;
		height: 44px;
		width: 44px;
		border-radius: 44px;
		font-size: 16px;
		background-color: $background-color;
		color: $font-color;
		transition: background-color 0.3s ease-in-out 0s, color 0.3s ease-in-out 0s;

		&:hover {
			color: $white;
			background-color: $brand-color;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
	}
}
