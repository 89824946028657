.brands {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	gap: $block-spacing * 2;
	width: 100%;
	color: $font-color;

	&__image {
		flex: 0 1 auto;
		width: calc(33.33% - #{$block-spacing * 2});
		background-repeat: no-repeat;
		background-size: contain;
		background-position: center;
		background-color: $white;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 46%;
		}
	}

	&__name {
		font-size: 16px;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;
		line-height: 32px;

		&:hover {
			color: $brand-color-2;
		}
	}

	&__letter {
		scroll-margin-top: 171px + $block-spacing;
		font-size: 40px;
		font-weight: 700;
		width: 10%;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			scroll-margin-top: 114px + $block-spacing;
		}
	}

	&__container {
		padding-bottom: $block-spacing * 2;
		display: flex;
		flex-flow: row wrap;
		width: 100%;
	}

	&__row {
		display: flex;
		flex-flow: row wrap;
		width: 90%;
	}

	&__col {
		width: 50%;
		padding-right: 25%;
		display: flex;
		flex-flow: column wrap;

		@include responsive(('desktop')) {
			padding-right: 20%;
		}

		@include responsive(('mobile-landscape', 'tablet', 'tablet-landscape')) {
			padding-right: 15%;
		}

		@include responsive(('mobile')) {
			width: 100%;
			padding-right: 0;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		margin-top: $vertical-spacing / 2;
	}
}
