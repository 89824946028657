@mixin wishlist {
	@at-root .wishlist {
		@extend %position-top-right, %button;

		font-size: 16px;
		color: $font-color;

		&__icon,
		&__loader {
			display: block !important;

			&--hidden {
				display: none !important;
			}
		}

		&--pdp {
			position: unset;
			top: auto;
			right: auto;
			color: $font-color;
			font-weight: 700;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			gap: $block-spacing * 1.5;
			cursor: pointer;
			height: 100%;

			i {
				color: $highlight-color-2;
				font-size: 24px;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				justify-content: flex-start;
				padding: $block-spacing * 2 0;
			}
		}

		&--account {
			top: 10%;
		}

		&--product {
			position: unset;
			min-height: 24px;
			display: flex;
			align-items: center;
			justify-content: flex-end;
			margin-left: auto;
		}

		&:hover {
			color: $highlight-color-2;
		}

		@content;
	}
}
