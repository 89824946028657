@include language {
	color: $black;
	padding: 0;

	&__item {
		font-size: 16px;
		padding: 0 $block-spacing;
		color: $white;
		font-weight: 600;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;

		img {
			margin-right: $block-spacing;
			width: 24px;
			height: 16px;
		}

		&:hover {
			background-color: transparent;
		}
	}

	&__items {
		padding: $block-spacing / 2 0;
		width: 100%;

		.language__item {
			color: $font-color;
			padding: $block-spacing / 2 $block-spacing;

			&:hover {
				color: $brand-color;
			}
		}
	}

	&__icon-left {
		font-size: 18px;
		margin: 0 $block-spacing 0 0;
	}

	&__icon-right {
		margin: 0 0 0 $block-spacing;
	}

	&--dropdown {
		height: 30px;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__item {
			font-size: 14px;
		}
	}
}
