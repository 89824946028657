@include filters {
	background-color: $white;
	padding: 0 $block-spacing * 4;
	padding-bottom: $block-spacing;
	border-radius: 16px;
	margin-top: $block-spacing * 2;
	color: $font-color;
	position: relative;

	&__container {
		@extend %flex-row;

		padding: $block-spacing * 2 0;
	}

	&__item {
		padding: $block-spacing 0;
		font-size: 16px;
		font-family: $secondary-font-family;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: space-between;

		&:hover {
			color: inherit;
		}

		&--heading {
			padding: $block-spacing * 2 0;
			font-size: 16px;
			font-weight: 700;
		}

		&--main-heading {
			padding: $block-spacing * 3 0;
			font-size: 18px;
			font-weight: 700;
		}

		&--underline {
			border-bottom: 1px solid $border-color;
		}

		&--active {
			color: $active-color;
		}

		a {
			&:hover {
				color: $highlight-color;
			}
		}
	}

	&__subitem {
		@extend %paragraph;

		width: 100%;
		font-family: $primary-font-family;
		padding: $block-spacing 0;
		font-size: 14px;

		&--sub {
			margin-left: $block-spacing * 2;
		}

		&--active {
			color: $active-color;
		}

		&--link {
			display: flex;
			align-items: center;
			justify-content: space-between;
		}

		&:hover {
			color: $brand-color-2;
		}
	}

	strong {
		font-weight: bold;
	}

	.button {
		font-size: 16px;
	}

	@include responsive(('tablet-landscape')) {
		padding-right: $block-spacing * 2;
		padding-left: $block-spacing * 2;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		display: none;
	}
}
