@mixin active-filters {
	@at-root .active-filters {
		@extend %flex-row;

		border: 1px solid $border-color;
		border-radius: 16px;
		padding: $block-spacing * 1.5;
		gap: $block-spacing;
		background-color: $white;
		align-items: center;
		justify-content: space-between;
		margin: 0 $block-spacing $block-spacing !important;
		width: calc(100% - #{$block-spacing * 2}) !important;

		&__container {
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: flex-start;
			gap: $block-spacing;
			flex: 1;
		}

		&__label {
			display: flex;
			cursor: pointer;
		}

		&__input {
			display: none;
		}

		&__replacement {
			display: none;
			position: relative;
			cursor: pointer;
			padding: 0;
			color: $font-color;
			font-weight: 700;
			font-size: 16px;
			padding-right: 18px;

			i {
				@extend %position-right;

				right: 0;
			}

			&:hover {
				background-color: transparent;
			}
		}

		&__input:checked + .active-filters__replacement {
			display: block;
		}

		&__count {
			@extend %flex-row;

			align-items: center;
			justify-content: center;
			color: $white;
			height: 30px;
			width: 30px;
			border-radius: 8px;
			font-size: 14px;
			background-color: $brand-color-2;
			font-weight: bold;
		}

		&__content {
			font-size: 16px;
			color: $font-color;
			padding-right: $block-spacing;
		}

		&__reset {
			@extend %button;

			font-size: 16px;
			color: $highlight-color-2;
			font-weight: 400;

			&:hover {
				text-decoration: underline;
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}

		@content;
	}
}
