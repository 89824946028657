@mixin checkbox() {
	@at-root .checkbox {
		@extend %paragraph;

		cursor: pointer;

		@include replacement('checkbox', false) {
			vertical-align: middle;
			height: 18px;
			width: 18px;
			border: 1px solid $border-color;
			border-radius: 5px;
			margin: calc(#{$block-spacing / 2}) $block-spacing calc(#{$block-spacing / 2}) 0;
			background: $white;

			&.checkbox__replacement--radio {
				border-radius: 18px;
				border: 4px solid $white;
				background: $white;
				box-shadow: 0 0 0 1px $black;
			}
		}

		@include replacement('checkbox', true) {
			background: url('#{$image-path}/icons/check-solid.svg') no-repeat center center;
			background-size: 12px;
			border: 1px solid $border-color;
			background-color: $white;

			&.checkbox__replacement--radio {
				background: black;
				border: 4px solid $white;
			}
		}

		&--filters {
			width: 33.3333%;
		}

		&--shoppingcart {
			display: block;
			width: calc(100% - #{$block-spacing * 2});
			border: 1px solid $border-color;
			border-radius: 3px;
			padding: $block-spacing;
			margin: $block-spacing;
			position: relative;

			img {
				@extend %position-right;

				right: $block-spacing;
				display: block;
				max-height: 40px;
				max-width: 100px;
			}
		}

		@content;
	}
}
