.reviews {
	margin-right: 16.66%;
	width: 33.32%;

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		margin-right: 0;
	}

	&__score {
		width: 65px;
		height: 65px;
		background-image: url('/_dekroo/img/icons/kiyoh.png');
		background-repeat: no-repeat;
		background-position: center;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
		background-size: cover;
		font-weight: 700;
	}

	&__summary {
		width: 100%;
		display: flex;
		flex-flow: column wrap;
		align-items: center;
		justify-content: center;
		gap: $block-spacing * 2;
		background-color: rgba($background-color-3, 0.15);
		border-radius: 8px;
		padding: $block-spacing * 4;
	}

	&__summary-top,
	&__summary-mid,
	&__summary-bottom {
		display: flex;
		flex-flow: row wrap;
		width: 100%;
		align-items: center;
		gap: $block-spacing * 2;

		.text {
			flex: 1;
		}

		.button {
			flex-grow: 1;
			max-width: 320px;
		}
	}

	&__summary,
	&__item {
		min-height: 268px;

		@include responsive(('mobile')) {
			min-height: 310px;
		}
	}

	&__item {
		width: 100%;
		border-radius: 16px;
		background-color: $white;
		border: 1px solid $border-color;
		padding: $block-spacing * 4;

		.text {
			height: 120px;
			overflow: hidden;
		}

		&--single {
			border: 0;
			padding: $block-spacing * 3 0 0;
			min-height: unset;

			.text {
				height: auto;
			}
		}
	}

	&__image {
		width: 55px;
		height: 55px;
		border-radius: 55px;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center;
		border: 1px solid $white;
		margin-left: -$block-spacing * 4;

		&:first-child {
			margin-left: 0;
		}
	}

	&__total {
		width: 55px;
		height: 55px;
		border-radius: 55px;
		border: 1px solid $white;
		color: $white;
		font-size: 16px;
		font-weight: 500;
		background-color: $brand-color-2;
		display: flex;
		align-items: center;
		justify-content: center;
		margin-left: -$block-spacing * 4;
	}

	&__stars {
		color: $highlight-color;
		display: flex;
		margin: $block-spacing 0 $block-spacing * 3;
		font-size: 20px;
	}
}
