@include number {
	flex-flow: nowrap;

	&__button {
		background-color: $white;
		color: $font-color;
		font-size: 14px;

		&--min {
			border-radius: 50px 0 0 50px;
		}

		&--plus {
			border-radius: 0 50px 50px 0;
		}

		&:hover {
			background-color: $background-color;
			color: $font-color;
		}
	}

	&--disabled {
		.number__button,
		.number__input {
			background: $background-color;
			pointer-events: none;
			user-select: none;
		}
	}
}
