@mixin sidebar() {
	@at-root .sidebar {
		position: relative;

		&__title {
			@extend %heading4;
		}

		&__item {
			@extend %paragraph;

			padding: calc(#{$block-spacing} / 2) 0;
			display: block;
			position: relative;

			&:hover,
			&--active {
				color: $brand-color;
			}

			&--category {
				@extend %heading6;

				font-weight: bold;
			}
		}

		@content;
	}
}
