@mixin account-nav {
	@at-root .account-nav {
		@extend %flex-row;
		@extend %paragraph;

		position: relative;
		width: 100%;

		&__item {
			text-align: center;
			position: relative;
			padding: $block-spacing;

			&--active {
				font-weight: bold;
			}

			&:hover {
				text-decoration: underline;
			}

			&:last-child {
				margin-left: auto;
				font-weight: bold;
			}
		}

		&__badge {
			height: 20px;
			width: 20px;
			line-height: 20px;
			text-align: center;
			border-radius: 20px;
			font-size: 10px;
			font-weight: 600;
			display: inline-block;
			background-color: $black;
			color: $white;
			margin: 0 0 0 $block-spacing * 0.5;
		}

		@content;
	}
}
