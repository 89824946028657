@mixin brand() {
	@at-root .brand {
		display: block;
		background-color: $white;
		height: 100%;
		width: 100%;
		position: relative;
		padding-bottom: 54px;

		.button {
			position: absolute;
			left: $block-spacing * 4;
			bottom: $block-spacing * 4;
			max-width: calc(100% - #{$block-spacing * 8});
		}

		&__image {
			display: block;
			width: 100%;
			background-position: center;
			background-size: cover;
			background-repeat: no-repeat;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 95%;
			}
		}

		&__info {
			padding: $block-spacing * 4;
		}

		&__name {
			@extend %heading5;

			color: $black;
			font-family: $secondary-font-family;
			font-weight: bold;
			margin-bottom: $block-spacing * 2;
		}

		&__content {
			@extend %paragraph;

			margin-bottom: $block-spacing * 2;
			color: $black;
		}

		@content;
	}
}
