@include product-info {
	&__container {
		@extend %flex-row;

		justify-content: space-between;
		align-items: center;
		gap: $block-spacing;

		&--spacing-bottom-3x {
			padding-bottom: $block-spacing * 3;
		}

		.button {
			flex: 1 1 auto;
		}
	}

	&__brand {
		color: $font-color;
		font-size: 16px;
		text-decoration: underline;
		margin-bottom: $block-spacing * 2;
	}

	&__name {
		font-size: 32px;
		font-weight: 700;
		font-family: $secondary-font-family;
		color: $black;
		text-transform: uppercase;
		margin-bottom: $block-spacing;
	}

	&__summary {
		@extend %small;

		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
	}

	&__description {
		@extend %paragraph;

		color: $black;
		margin-bottom: $block-spacing * 2;
	}

	&__price {
		@extend %flex-row;

		margin-bottom: $block-spacing * 2;
		align-items: center;
		justify-content: space-between;
	}

	&__price-highlight {
		font-size: 24px;
		font-weight: 700;
		color: $brand-color-2;
	}

	&__price-advice {
		font-size: 18px;
		color: $font-color-2;
		text-decoration: line-through;

		&--hidden {
			display: none;
		}
	}

	&__price-container {
		display: flex;
		align-items: center;
		gap: $block-spacing;
	}

	&__price-save {
		font-size: 14px;
		color: $font-color;

		i {
			margin-right: 6px;
		}

		&--hidden {
			display: none;
		}
	}

	&__credits {
		display: flex;
		align-items: center;
		font-size: 14px;
		color: $font-color;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 3;

		img {
			width: 14px;
			margin-right: 6px;
		}

		&--hidden {
			padding-bottom: 0;

			& * {
				display: none;
			}
		}
	}

	&__option {
		margin-top: $block-spacing;
		margin-bottom: $block-spacing * 2;
		color: $font-color;
		font-size: 14px;
		font-weight: 700;

		&--size-chart {
			font-weight: 400;
			cursor: pointer;
		}
	}

	&__tags {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		gap: $block-spacing;
		padding-bottom: $block-spacing * 2;
		flex-wrap: wrap;
	}

	&__tag {
		background-color: $black;
		color: $white;
		font-size: 12px;
		font-weight: 700;
		text-transform: uppercase;
		padding: 6px 7px;
		border-radius: 50px;

		&--1 {
			background-color: $highlight-color-2;
		}

		&--2 {
			background-color: $background-color-3;
		}

		&--3 {
			background-color: $black;
		}
	}

	&__message {
		color: $font-color;
		font-size: 14px;
		display: flex;
		gap: $block-spacing;
		align-items: center;
		font-weight: 400;
		margin-bottom: $block-spacing * 3;

		&--success {
			color: $brand-color-2;
		}

		&--error {
			color: $highlight-color-2;
		}

		&--hidden {
			display: none;
		}
	}

	&__quantity {
		display: flex;
		gap: $block-spacing * 2;
		flex-flow: row wrap;
		font-size: 14px;
		color: $font-color;
		border-top: 1px solid $border-color;
		padding-top: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;

		.quantity-prices__price {
			color: $brand-color-2;
		}

		i {
			color: $brand-color-2;
			margin-right: $block-spacing;
		}
	}

	&__usp {
		display: flex;
		gap: $block-spacing * 2;
		flex-flow: row wrap;
		font-size: 14px;
		color: $font-color;
		border-top: 1px solid $border-color;
		padding-top: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;

		i {
			color: $brand-color-2;
			margin-right: $block-spacing;
			min-width: 18px;
		}

		&--no-border {
			border-top: 0;
			padding-top: 0;
		}
	}

	&__usp-item {
		width: 100%;

		&--underline {
			text-decoration: underline;
		}
	}
}
