@mixin background() {
	@at-root .background {
		background-color: $background-color;
		width: 100%;

		&--white {
			background-color: $white;
		}

		&--logo {
			background-image: url('/_dekroo/img/dkr.png');
			background-repeat: no-repeat;
			background-position: center;
		}

		&--hero-logo {
			background-image: url('/_dekroo/img/dkr.png');
			background-repeat: no-repeat;
			background-position: center right 8%;
			background-color: transparent;
			height: 100%;
			max-height: 500px;
			overflow-y: auto;
			background-size: auto 80%;
			display: flex;
			align-items: center;

			.background__container {
				padding: $vertical-spacing / 2 16%;

				.text {
					margin: $block-spacing 0 $block-spacing * 2;
				}

				@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
					padding: $block-spacing * 3;
				}
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				max-height: unset;
			}
		}
		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&--grow {
				display: flex;
				flex: 1 1 auto;
				justify-content: space-between;
				flex-flow: column wrap;
			}
		}

		&--bottom-2 {
			position: relative;

			&::after {
				content: ' ';
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				width: 100%;
				height: 27px;
				background-image: url('/_dekroo/img/bottom-bg-2.svg');
				background-position: center top;
				z-index: 1;
			}
		}

		&--bottom-white {
			position: relative;

			&::after {
				content: ' ';
				position: absolute;
				top: 100%;
				left: 0;
				right: 0;
				width: 100%;
				height: 27px;
				background-image: url('/_dekroo/img/bottom-bg-white.svg');
				background-position: center top;
				z-index: 1;
			}
		}

		&--2 {
			background-color: $background-color-2;
		}

		&--3 {
			background-color: $background-color-3;
		}

		&--4 {
			background-color: rgba($brand-color, 0.15);
		}

		&--brand-color {
			background-color: $brand-color-3;
		}

		&--border {
			border: 1px solid $border-color;
		}

		&--radius {
			border-radius: 8px;
		}

		&--radius-16 {
			border-radius: 16px;
		}

		&--overflow-h {
			overflow: hidden;
		}

		&--spacing {
			padding: $block-spacing * 8;

			@include responsive(('mobile', 'mobile-landscape')) {
				padding: $block-spacing * 4;
			}
		}

		&--spacing-small {
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape')) {
				padding: $block-spacing * 3;
			}
		}

		&--product-cs {
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding: $block-spacing * 4 8.3333%;
			}
		}

		&--faq {
			padding: $block-spacing * 4;
		}

		&--product-usp {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4 $block-spacing * 4 $block-spacing * 2 $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--product-description {
			margin-top: $block-spacing * 2;
			padding: $block-spacing * 4;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				padding-left: 8.3333%;
				padding-right: 8.3333%;
			}
		}

		&--brand {
			min-height: 393px;
			display: flex;
			align-items: center;
			border-radius: 16px;
			border: 1px solid $border-color;
			margin-top: $block-spacing * 2;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				margin: 0;
				min-height: unset;
				border-radius: 0 0 16px 16px;
				border: 0;
			}
		}

		&--look {
			background: linear-gradient(to right, transparent 0%, transparent 30%, $white 30%, $white 100%);

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				background: $white;
			}
		}

		&--vertical-spacing {
			margin-top: $vertical-spacing;
			margin-bottom: $vertical-spacing;
		}

		&--vertical-spacing-top {
			margin-top: $vertical-spacing;
		}

		&--vertical-spacing-bottom {
			margin-bottom: $vertical-spacing;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			&--vertical-spacing {
				margin-top: $vertical-spacing-mobile;
				margin-bottom: $vertical-spacing-mobile;
			}

			&--vertical-spacing-top {
				margin-top: $vertical-spacing-mobile;
			}

			&--vertical-spacing-bottom {
				margin-bottom: $vertical-spacing-mobile;
			}
		}

		@content;
	}
}
