@mixin address {
	@at-root .address {
		width: 100%;

		&__close {
			@extend %position-right;

			right: $block-spacing;
			cursor: pointer;
		}

		&--hidden {
			display: none;
		}

		@content;
	}
}
