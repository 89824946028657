.copyright {
	@extend %flex-row;

	align-items: center;
	justify-content: flex-start;
	width: 25%;
	padding: $block-spacing;

	&__item {
		color: $font-color;
		font-size: 16px;
		line-height: 1.5em;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		justify-content: center;
	}
}
