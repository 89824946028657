@mixin product-overview {
	@at-root .product-overview {
		width: 100%;
		position: relative;
		min-height: 100px;

		&__content {
			@extend %flex-row;

			align-items: stretch;
			width: 100%;
		}

		&__loader {
			@extend %flex-row;

			align-items: center;
			justify-content: center;
			color: $black;
			display: none;

			&--active {
				display: flex;
			}
		}

		@content;
	}
}
