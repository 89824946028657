@include sorting {
	justify-content: space-between;
	margin-bottom: $block-spacing * 2;
	padding-right: $block-spacing;
	padding-top: $block-spacing;

	&__content {
		font-size: 16px;
		color: $font-color;
		padding: $block-spacing;
	}

	&__select {
		background-color: $white;
		border: 1px solid $border-color;
		font-size: 14px;
		color: $font-color;
		padding: $block-spacing * 2 $block-spacing * 5 $block-spacing * 2 $block-spacing * 3;
		background-position: center right $block-spacing * 2;
		border-radius: 50px;
		min-width: 216px;
		transition: border 0.3s ease-in-out 0s;

		&:hover {
			border: 1px solid $brand-color;
		}
	}

	&--news {
		padding: 0 $block-spacing 0 0;
		margin-top: $block-spacing * 3;
		position: relative;
		width: calc(100% - 330px);

		&::before {
			content: ' ';
			display: block;
			position: absolute;
			top: 50%;
			left: $block-spacing;
			right: $block-spacing;
			height: 1px;
			background-color: $border-color;
		}

		.sorting__select {
			padding-top: 10px;
			padding-bottom: 10px;
		}
	}

	@include responsive(('tablet')) {
		&--news {
			width: calc(100% - 220px);
		}
	}

	@include responsive(('mobile-landscape')) {
		&--news {
			.sorting__content {
				padding-bottom: 0;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&--news {
			width: 100%;
		}
	}

	@include responsive(('mobile')) {
		&--news {
			padding: 0;

			&::before {
				top: 44px;
			}

			.sorting__select {
				width: calc(100% - #{$block-spacing * 2});
				margin: 0 $block-spacing;
			}
		}
	}
}
