@mixin carousel {
	@at-root .carousel {
		width: 100%;
		white-space: nowrap;
		overflow: hidden;
		opacity: 0;
		transition: opacity 300ms ease-in-out;

		&--initialized {
			opacity: 1;
		}

		&__slides {
			height: 100%;
			font-size: 0;
			display: flex;
			align-items: stretch;
			flex-flow: row nowrap;
		}

		&__slide {
			vertical-align: top;
			display: flex;
			flex: 0 0 auto;
			white-space: normal;
			padding: $block-spacing;

			&--no-spacing {
				padding: 0;
			}
		}

		&__prev {
			@extend %position-left;

			left: -14px;
			border-width: 0;
		}

		&__next {
			@extend %position-right;

			right: -14px;
			border-width: 0;
		}

		&__next,
		&__prev {
			outline: none;
			cursor: pointer;
			height: 44px;
			width: 44px;
			border-radius: 44px;
			color: $font-color;
			font-size: 18px;
			display: flex;
			align-items: center;
			justify-content: center;
			background-color: $background-color;
			transition: all 0.3s ease-in-out 0s;
			z-index: 1;

			&:hover {
				background-color: rgba($brand-color-2, 0.8);
				color: $white;
			}

			&--2 {
				background-color: $font-color;
				color: $white;
			}
		}

		&__bullet {
			height: 20px;
			width: 20px;
			border-radius: 20px;
			background-color: $background-color;
			margin: $block-spacing;
			cursor: pointer;

			&:hover {
				background-color: $hover-color;
			}

			&--active {
				background-color: $active-color;
			}
		}

		&__progress {
			@extend %flex-row;

			position: absolute;
			justify-content: center;
			align-items: center;
			padding: $block-spacing;

			&--bar {
				position: unset;
				top: auto;
				left: auto;
				right: auto;
				bottom: auto;
				padding: 0;

				.carousel__bullet {
					flex: 1 1 auto;
					height: 8px;
					margin: 0;
					border-radius: 0;
					background-color: $background-color-4;

					&--active {
						background-color: $brand-color;
					}
				}
			}
		}

		&--visable {
			overflow: visible;
		}

		@content;
	}
}
