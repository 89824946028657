.calendar {
	margin-top: $block-spacing * 4;

	&__container {
		display: flex;
		flex-flow: row wrap;
		border-bottom: 1px solid $border-color;
		padding: $block-spacing 0 0;
		width: 100%;

		&:last-child {
			border: 0;
		}
	}

	&__button {
		@extend %button;

		color: $font-color;
		font-size: 14px;
		font-weight: bold;
		width: 33.33%;
		text-align: center;
		text-transform: uppercase;
		border-radius: 20px;
		padding: $block-spacing;
		margin-bottom: $block-spacing;
		transition: background-color 0.3s ease-in-out 0s;

		&:hover {
			background-color: $highlight-color;
		}
	}

	&__year {
		color: $font-color;
		font-size: 14px;
		font-weight: bold;
		width: 33.33%;
		text-align: center;
		text-transform: uppercase;
		margin-bottom: $block-spacing;
		padding: $block-spacing;
	}

	&__replacement {
		transition: background-color 0.3s ease-in-out 0s;
		border-radius: 20px;
		padding: $block-spacing;
	}

	&__item {
		border-right: 1px solid $border-color;
		text-transform: uppercase;
		color: $font-color;
		font-size: 14px;
		font-weight: bold;
		padding: $block-spacing / 2 $block-spacing;
		cursor: pointer;
		flex: 1;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;

		&:last-child {
			border: 0;
		}

		&--active {
			.calendar__replacement {
				background-color: $highlight-color;
			}
		}
	}

	&__input {
		display: none;

		&:checked + .calendar__replacement {
			background-color: $highlight-color;
		}
	}

	@include responsive(('mobile-landscape', 'tablet', 'tablet-landscape')) {
		&__item {
			width: 16.66%;
			flex: unset;

			&:nth-child(6n) {
				border: 0;
			}
		}
	}

	@include responsive(('mobile')) {
		&__item {
			width: 25%;
			flex: unset;

			&:nth-child(4n) {
				border: 0;
			}
		}

		&__button,
		&__year {
			width: auto;
			flex: 1;
		}
	}
}
