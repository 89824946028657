@mixin news-item() {
	@at-root .news-item {
		position: relative;
		display: flex;
		flex-flow: column wrap;
		height: 100%;
		width: 100%;
		border: 1px solid $border-color;
		border-radius: 8px;
		overflow: hidden;

		&__image {
			position: relative;
			width: 100%;
			height: auto;
			background-size: cover;
			background-position: center;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 84%;
			}
		}

		&__content {
			position: relative;
			padding: $block-spacing * 4;
			display: flex;
			flex-flow: column wrap;
			flex: 1 1 auto;
			justify-content: space-between;
		}

		&__category {
			top: -11px;
			position: absolute;
			background-color: $background-color-3;
			color: $white;
			padding: 5px 7px;
			text-transform: uppercase;
			font-weight: 700;
			border-radius: 50px;
			font-size: 12px;

			&--black {
				background-color: $black;
			}
		}

		&__date {
			font-size: 12px;
			line-height: normal;
			color: $font-color;
			text-transform: uppercase;
			margin-bottom: $block-spacing * 2;
		}

		&__title {
			font-size: 16px;
			text-transform: uppercase;
			font-weight: 700;
			line-height: normal;
			color: $font-color;
			margin-bottom: $block-spacing;
		}

		&__text {
			border-top: 1px solid $border-color;
			font-size: 16px;
			line-height: normal;
			padding-top: $block-spacing * 2;
			padding-bottom: $block-spacing * 2;
			color: $font-color;
		}

		&__link {
			color: $highlight-color;
		}

		&--calendar {
			.news-item__title {
				font-size: 20px;
			}

			.news-item__category {
				font-size: 14px;
				background-color: $brand-color;
			}
		}

		&--active {
			border-color: $brand-color;
		}

		@content;
	}
}
