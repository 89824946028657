@mixin shoppingcart-table {
	@at-root .shoppingcart-table {
		width: 100%;
		overflow-x: auto;

		&__table {
			white-space: nowrap;
			width: 100%;
		}

		&__body {
			overflow-x: auto;
			max-width: 100%;
		}

		&__cell,
		&__head {
			text-align: left;
			padding: $block-spacing 0;
			vertical-align: middle;

			&--align-right {
				text-align: right;
			}
		}

		&__head {
			@extend %heading6;

			font-weight: bold;
		}

		&__cell {
			@extend %paragraph;

			border-bottom: 1px solid $border-color;

			&--success,
			&--warning,
			&--error {
				position: relative;
				padding-left: calc(#{$block-spacing} + 10px);

				&::before {
					@extend %position-left;

					display: block;
					height: 10px;
					width: 10px;
					border-radius: 10px;
					content: ' ';
				}
			}

			&--product-name {
				border: 0;
				padding-bottom: 0;
			}

			&--product-image {
				width: calc(#{$block-spacing} + 100px);
				padding-right: $block-spacing;
			}

			&--success {
				&::before {
					background-color: $success-color;
				}
			}

			&--warning {
				&::before {
					background-color: $warning-color;
				}
			}

			&--error {
				&::before {
					background-color: $error-color;
				}
			}
		}

		&__row {
			&--hidden {
				display: none;
			}

			&:last-child {
				.shoppingcart-table__cell {
					border: 0;
				}
			}
		}

		&__link {
			font-weight: bold;

			&:hover {
				text-decoration: none;
				color: $highlight-color;
			}
		}

		&__image-placeholder {
			position: relative;
			width: 100%;

			&::after {
				display: block;
				content: ' ';
				padding-bottom: 100%;
			}
		}

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;
		}

		&__delete {
			@extend %button;

			padding: $block-spacing;
		}

		@content;
	}
}
