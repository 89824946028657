@mixin shoppingcart-giftcard {
	@at-root .shoppingcart-giftcard {
		&__content {
			@extend %paragraph;

			margin-bottom: $block-spacing;
			font-weight: bold;

			&--remove {
				margin-top: $block-spacing * 2;
				margin-bottom: 0;
			}
		}

		&__input {
			@extend %input;

			height: 54px;
			line-height: 54px;
			width: 100%;
			border: 1px solid $border-color;
			border-radius: 3px;
			background-color: $white;
			outline: none;
			padding: 0 100px 0 $block-spacing;
		}

		&__button {
			@extend %button;

			position: absolute;
			right: $block-spacing;
			top: 50%;
			transform: translateY(-50%);
			background-color: $background-color;
			padding: $block-spacing $block-spacing * 3;
			width: auto;
			height: 37px;
			max-width: none;
			transition: all 0.3s ease-in-out 0s;
			vertical-align: top;
			text-align: center;
			border: 0;
			display: inline-block;
			line-height: 18px;
			outline: none;

			&:hover {
				background-color: $brand-color;
				color: $white;
			}
		}

		&__form {
			position: relative;
		}

		&__text {
			width: 50%;
			margin-top: 10px;
			font-size: 14px;
			line-height: 1.5em;
		}

		&__container {
			display: flex;
			flex-flow: row wrap;
			gap: $block-spacing;
			align-items: center;
			justify-content: space-between;

			.shoppingcart-giftcard__text {
				padding: $block-spacing 0;
				flex: 1;
				width: auto;
				margin-top: 0;
			}

			.shoppingcart-giftcard__button {
				position: unset;
				transform: none;
				top: auto;
				right: auto;
			}
		}

		@content;
	}
}
