@mixin form {
	@at-root .form {
		margin: 0;
		width: 100%;

		&--inline {
			width: auto;
		}

		&__b2b {
			width: 100%;

			&--hidden {
				display: none;
			}
		}

		&__vat-message {
			display: none;
			color: $error-color;
			padding: 16px 0 0;

			&--active {
				display: block;
			}
		}

		@content;
	}
}
