@mixin guide {
	@at-root .guide {
		@extend %flex-row;

		align-items: center;

		&__item {
			padding: calc(#{$block-spacing} / 2);

			&--active {
				font-weight: bold;
			}

			&:hover {
				text-decoration: underline;
			}

			&:first-child {
				padding-left: 0;
			}

			&:last-child {
				padding-right: 0;
			}
		}

		&__icon {
			margin: 0 calc(#{$block-spacing} / 2);
		}

		@content;
	}
}
