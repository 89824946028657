@mixin header {
	@at-root .header {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		padding: $block-spacing;
		width: 100%;
		border-bottom: 1px solid $border-color;

		@content;
	}
}
