%clear {
	&::after {
		content: ' ';
		clear: both;
		display: block;
	}
}

%button {
	text-align: center;
	display: inline-block;
	cursor: pointer;
	background: none;
	border: 0;
	text-decoration: none;
}

%input {
	font-family: $primary-font-family;
	color: $font-color;
	vertical-align: top;
	display: inline-block;
	background: none;
	border: 1px solid $border-color;
	outline: none;
}

%select {
	font-family: $primary-font-family;
	color: $font-color;
	cursor: pointer;
	appearance: none;
	border: 0;
	border-radius: 0;
	padding: 0;
	outline: none;
	position: relative;
	background: none;
}

%flex-row {
	display: flex;
	flex-flow: row wrap;
}

%flex-column {
	display: flex;
	flex-flow: column wrap;
}

%flex-stretch {
	display: flex;
	align-items: stretch;
	flex-wrap: wrap;
}

%position-center {
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
}

%position-top-left {
	position: absolute;
	top: 0;
	left: 0;
}

%position-top {
	position: absolute;
	top: 0;
	left: 50%;
	transform: translateX(-50%);
}

%position-top-right {
	position: absolute;
	top: 0;
	right: 0;
}

%position-right {
	position: absolute;
	top: 50%;
	right: 0;
	transform: translateY(-50%);
}

%position-bottom-right {
	position: absolute;
	bottom: 0;
	right: 0;
}

%position-bottom {
	position: absolute;
	bottom: 0;
	left: 50%;
	transform: translateX(-50%);
}

%position-bottom-left {
	position: absolute;
	bottom: 0;
	left: 0;
}

%position-left {
	position: absolute;
	top: 50%;
	left: 0;
	transform: translateY(-50%);
}
