@mixin list {
	@at-root .list {
		padding: 0;
		margin: 0;
		list-style: none;

		&__item {
			@extend %paragraph;
		}

		@content;
	}
}
