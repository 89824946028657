.contact-info {
	color: $black;
	line-height: 1.5em;

	&__heading {
		font-size: 18px;
		font-family: $secondary-font-family;
		font-weight: bold;
		margin-bottom: $block-spacing * 2;
	}

	&__item {
		display: flex;
		align-items: center;
		font-size: 16px;
		font-weight: bold;
		margin-bottom: $block-spacing;

		i {
			font-size: 20px;
			margin-right: 12px;
			width: 20px;
		}
	}

	&__sub-item {
		font-size: 14px;
		margin-bottom: $block-spacing * 2;
		padding-left: 32px;

		a {
			text-decoration: underline;
		}
	}

	&__socials {
		display: flex;
		align-items: center;
		justify-content: flex-start;
		padding-left: 32px;
	}

	&__social {
		color: $white;
		background-color: $black;
		height: 32px;
		width: 32px;
		border-radius: 32px;
		display: flex;
		align-items: center;
		justify-content: center;
		text-decoration: none;
		margin-right: $block-spacing;
	}
}
