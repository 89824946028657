@mixin product-info {
	@at-root .product-info {
		&__name {
			@extend %heading2;
		}

		&__size-name,
		&__color-name {
			@extend %heading6;

			margin: $block-spacing 0;
		}

		&__stock {
			@extend %paragraph;

			margin-bottom: $block-spacing;

			&--success {
				color: $success-color;
			}

			&--warning {
				color: $warning-color;
			}

			&--error {
				color: $error-color;
			}
		}

		&__price {
			@extend %flex-row;

			align-items: center;

			&__advice {
				@extend %heading6;

				text-decoration: line-through;
			}

			&__highlight {
				@extend %heading5;

				color: $highlight-color;
				margin-right: $block-spacing;
			}
		}

		@content;
	}
}
