.star-burst {
	min-width: 78px;
	padding: 0 $block-spacing * 2;
	aspect-ratio: 1;
	background-color: $highlight-color-2;
	font-family: $primary-font-family;
	font-weight: 700;
	display: flex;
	align-items: center;
	justify-content: center;
	color: $white;
	font-size: 14px;
	line-height: 18px;
	cursor: pointer;
	z-index: 8;
    /* stylelint-disable */
	clip-path: polygon(
		100% 50%,
		93.79% 54.31%,
		99.04% 59.75%,
		92.11% 62.77%,
		96.19% 69.13%,
		88.8% 70.74%,
		91.57% 77.78%,
		84.01% 77.91%,
		85.36% 85.36%,
		77.91% 84.01%,
		77.78% 91.57%,
		70.74% 88.8%,
		69.13% 96.19%,
		62.77% 92.11%,
		59.75% 99.04%,
		54.31% 93.79%,
		50% 100%,
		45.69% 93.79%,
		40.25% 99.04%,
		37.23% 92.11%,
		30.87% 96.19%,
		29.26% 88.8%,
		22.22% 91.57%,
		22.09% 84.01%,
		14.64% 85.36%,
		15.99% 77.91%,
		8.43% 77.78%,
		11.2% 70.74%,
		3.81% 69.13%,
		7.89% 62.77%,
		0.96% 59.75%,
		6.21% 54.31%,
		0% 50%,
		6.21% 45.69%,
		0.96% 40.25%,
		7.89% 37.23%,
		3.81% 30.87%,
		11.2% 29.26%,
		8.43% 22.22%,
		15.99% 22.09%,
		14.64% 14.64%,
		22.09% 15.99%,
		22.22% 8.43%,
		29.26% 11.2%,
		30.87% 3.81%,
		37.23% 7.89%,
		40.25% 0.96%,
		45.69% 6.21%,
		50% 0%,
		54.31% 6.21%,
		59.75% 0.96%,
		62.77% 7.89%,
		69.13% 3.81%,
		70.74% 11.2%,
		77.78% 8.43%,
		77.91% 15.99%,
		85.36% 14.64%,
		84.01% 22.09%,
		91.57% 22.22%,
		88.8% 29.26%,
		96.19% 30.87%,
		92.11% 37.23%,
		99.04% 40.25%,
		93.79% 45.69%
	);
    /* stylelint-enable */
	&--product {
		position: absolute;
		top: 32px;
		right: 32px;
	}
}
