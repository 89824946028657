@mixin storelocator {
	@at-root .storelocator {
		@extend %flex-row;

		position: relative;

		&__search {
			@extend %position-top-left;

			width: 50%;
			padding: $block-spacing;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				width: 100%;
			}
		}

		&__icon {
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: #{$block-spacing * 2};
			font-size: 16px;
			color: $font-color;
		}

		&__input {
			vertical-align: top;
			height: 50px;
			width: 100%;
			max-width: none;
			border: 1px solid $border-color;
			background: $white;
			color: $font-color;
			font-family: $primary-font-family;
			font-size: 16px;
			line-height: 50px;
			padding: $block-spacing $block-spacing $block-spacing #{$block-spacing * 3};
			display: inline-block;
			outline: none;
		}

		&__stores {
			overflow-y: auto;
			height: 505px;
			width: 50%;
			border: 1px solid $border-color;
			padding: $block-spacing;
			padding-top: calc(50px + #{$block-spacing * 2});

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				height: 300px;
				width: 100%;
			}
		}

		&__store {
			border-bottom: 1px solid $border-color;
			color: $font-color;
			font-family: $primary-font-family;
			line-height: 22px;
			padding: 0 0 $block-spacing 0;
			margin-bottom: $block-spacing;

			&--hidden {
				display: none;
			}
		}

		&__name {
			font-size: 13px;
			font-weight: 700;
			cursor: pointer;
		}

		&__address {
			font-size: 13px;
		}

		&__info {
			font-size: 12px;
			font-weight: 700;
		}

		&__map {
			width: 50%;
			height: 505px;
			border: 1px solid $border-color;
			border-left: 0;
			background-color: $background-color;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				height: 400px;
				width: 100%;
			}
		}

		@content;
	}
}
