.brand-nav {
	display: flex;
	flex-flow: row wrap;
	gap: $block-spacing;
	margin-bottom: $block-spacing * 3;

	&__item {
		font-size: 16px;
		color: $font-color;
		width: 44px;
		height: 44px;
		display: flex;
		align-items: center;
		justify-content: center;
		border: 1px solid $border-color;
		border-radius: 8px;

		&:hover {
			font-weight: 700;
			border: 1px solid $brand-color;
		}

		&--disabled {
			cursor: not-allowed;
			color: $font-color-2;

			&:hover {
				font-weight: unset;
			}
		}
	}
}
