@include search {
	flex-grow: unset;
	position: unset;
	width: 100%;

	&__placeholder {
		position: relative;
		z-index: 9;
	}

	&__icon {
		font-size: 14px;
		position: absolute;
		right: 5px;
		color: $font-color;
		top: 50%;
		transform: translateY(-50%);
		background-color: $background-color;
		width: 34px;
		height: 34px;
		border-radius: 34px;
		display: flex;
		align-items: center;
		justify-content: center;
		cursor: pointer;
	}

	&__input {
		font-size: 14px;
		color: $font-color;
		width: 100%;
		background-color: $white;
		padding: $block-spacing * 1.5 $block-spacing * 2;
		height: 44px;
		padding-right: 44px;
		border-radius: 50px;

		&:focus {
			border-color: $border-color;
		}
	}

	&__overlay {
		position: fixed;
		bottom: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
		background-color: rgba($brand-color, 0.1);
		z-index: 9;
		display: none;

		&--active {
			display: block;
		}
	}

	&__dropdown {
		border-radius: 0 0 8px 8px;
		width: calc(#{$grid-width} - #{$block-spacing * 2});
		max-width: 100%;
		left: 50%;
		transform: translateX(-50%);
		right: auto;
		overflow-y: auto;
		max-height: calc(100vh - 100px);
		height: 477px;
		z-index: 11;
		flex-flow: row wrap;

		&--active {
			display: flex;
		}

		&-icon {
			display: flex;
			justify-content: center;
			margin-bottom: $block-spacing;
		}
	}

	&__dropdown-container {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		flex: 1;
	}

	&__dropdown-left {
		display: flex;
		flex-flow: column nowrap;
		justify-content: space-between;
		width: 75%;
		padding: $block-spacing * 4 0;
	}

	&__dropdown-top {
		flex: 0;
		padding: 0 $block-spacing * 3;
	}

	&__dropdown-center {
		flex: 1;
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
	}

	&__dropdown-bottom {
		flex: 0 0;
		padding: 0 $block-spacing * 3;
	}

	&__dropdown-products {
		width: 33.33%;
		height: 100%;
		border-right: 1px solid $border-color;
		padding: $block-spacing * 2 $block-spacing * 3;
		gap: $block-spacing * 2;
		display: flex;
		justify-content: space-between;
		flex-flow: column wrap;

		&:last-child {
			border: 0;
		}
	}

	&__dropdown-right {
		display: flex;
		flex-flow: column wrap;
		justify-content: space-between;
		width: 25%;
		background-color: $background-color-2;
		border-left: 1px solid $border-color;
		padding: $block-spacing * 3 $block-spacing;
	}

	&__sub-items {
		flex-grow: 1;
	}

	&__sub-item {
		display: block;
		font-size: 16px;
		color: $font-color;
		line-height: 32px;
		font-weight: 400;
		width: 100%;
		padding: 0 $block-spacing * 2;
		position: relative;
		cursor: pointer;

		&--text {
			padding: 0 $block-spacing * 2;
		}

		&:hover,
		&--active {
			background-color: $background-color;
			border-radius: 8px;
			text-decoration: underline;
		}

		&--heading {
			text-transform: uppercase;
			font-weight: 700;
			display: flex;
			flex-flow: row wrap;
			align-items: center;
			justify-content: space-between;
			gap: $block-spacing * 2;

			&::after {
				content: ' ';
				display: block;
				height: 1px;
				flex: 1;
				background-color: $border-color;
			}

			&:hover {
				background-color: unset;
				border-radius: unset;
				text-decoration: unset;
			}
		}

		&--view-all {
			text-decoration: underline;
			font-weight: 700;

			&:hover {
				background-color: unset;
				border-radius: unset;
				text-decoration: unset;
			}
		}
	}

	&__product {
		display: flex;
		align-items: flex-start;
		width: 100%;
	}

	&__product-image {
		width: 25%;
		text-align: center;

		img {
			display: inline-block;
			max-width: 100%;
		}
	}

	&__product-info {
		flex: 1;
		padding-left: $block-spacing * 2;
	}

	&__product-name {
		font-size: 16px;
		color: $font-color;
		text-decoration: underline;
		display: block;
	}

	&__product-summary {
		font-size: 16px;
		color: $font-color;
		font-weight: 700;
		text-transform: uppercase;
	}

	&__product-colors {
		@extend %flex-row;

		align-items: center;
		gap: $block-spacing / 2;
	}

	&__product-color {
		cursor: pointer;
	}

	&__product-color-code {
		display: block;
		width: 18px;
		height: 18px;
		border: 1px solid $border-color;
		border-radius: 18px;
		margin: 3px;
	}

	&__product-color-replacement {
		display: block;
		width: 26px;
		height: 26px;
		border-radius: 26px;
		border: 1px solid transparent;
	}

	&__product-color-input {
		display: none;

		&:checked + .search__product-color-replacement {
			border: 1px solid $brand-color;
		}
	}

	&__product-price {
		display: flex;
		flex-flow: row wrap;
		justify-content: flex-start;

		&__advice {
			font-size: 16px;
			color: $font-color-2;
			text-decoration: line-through;
			margin-right: $block-spacing;
		}

		&__highlight {
			color: $font-color;
			font-size: 16px;
			font-weight: 700;
		}

		&__message {
			font-size: 12px;
			color: $font-color;
			font-weight: normal;
			width: 100%;
		}
	}

	&__dropdown-overlay {
		background-color: rgb(0 0 0 / 50%);
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		display: none;

		&-content {
			display: flex;
			justify-content: center;
			align-content: center;
			flex-flow: column wrap;
			height: 100%;
			color: white;
		}

		&--active {
			display: block;
		}
	}

	&--news {
		flex: 1;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-end;
		width: 330px;
		padding: $block-spacing * 3 $block-spacing $block-spacing * 2;

		.search__placeholder {
			width: 100%;
			z-index: 1;
		}

		.search__dropdown,
		.search__overlay {
			&--active {
				display: none;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		position: fixed;
		top: 94px;
		left: 0;
		right: 0;
		width: 100%;
		background-color: $background-color-2;
		padding: $block-spacing * 2;
		display: none;

		&__input {
			width: 100%;
		}

		&__overlay {
			display: none;

			&--active {
				display: none;
			}
		}

		&__dropdown {
			width: 100%;
			border-radius: 0;
			position: fixed;
			top: 170px;
			right: 0;
			bottom: 0;
			border: 0;
			left: 0;
			max-height: calc(100vh - 170px);
			height: 100vh;
			transform: unset;
		}

		&__dropdown-container {
			flex-flow: column wrap;
		}

		&__dropdown-left {
			width: 100%;
			flex: 1;
		}

		&__dropdown-products {
			justify-content: flex-start;
		}

		&__dropdown-right {
			width: 100%;
		}

		&--active {
			display: block;
		}

		&--news {
			background-color: unset;
			display: flex;
			position: unset;
			top: unset;
			left: unset;
			right: unset;
			padding: $block-spacing * 3 $block-spacing $block-spacing * 2;
		}
	}

	@include responsive(('tablet')) {
		&__dropdown-products {
			flex-flow: row wrap;
			justify-content: flex-start;
		}

		&__product {
			width: 50%;
			max-width: calc(50% - #{$block-spacing});
			flex: 1;
		}

		&--news {
			width: 220px;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__dropdown-products {
			width: 100%;
			border: 0;
			height: auto;
		}
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&--news {
			padding-top: 0;
		}
	}
}
