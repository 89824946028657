@include sidebar {
	background-color: $white;
	padding: $block-spacing * 4 $block-spacing * 4 $block-spacing * 2;
	border: 1px solid $border-color;
	border-radius: 16px;
	margin-bottom: $block-spacing * 2;

	&__heading {
		font-size: 18px;
		color: $font-color;
		font-weight: 700;
		font-family: $primary-font-family;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
	}

	&__author {
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		border-bottom: 1px solid $border-color;
		padding-bottom: $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
		gap: $block-spacing * 2;
	}

	&__author-image {
		height: 68px;
		width: 68px;
		border-radius: 68px;
		background-size: cover;
		background-position: center;
		background-repeat: no-repeat;
	}

	&__author-name {
		font-size: 18px;
		color: $font-color;
		line-height: normal;
	}

	&__author-sub {
		font-size: 14px;
		color: $brand-color-2;
		line-height: normal;
	}

	&__info {
		font-size: 14px;
		line-height: 27px;
		color: $font-color;
		padding-bottom: $block-spacing;
	}

	&__item {
		font-size: 16px;
		color: $font-color;
		font-weight: 700;
		font-family: $primary-font-family;
		padding: $block-spacing * 2 0;
		border-bottom: 1px solid $border-color;
		display: flex;
		align-items: center;
		justify-content: space-between;

		&:hover {
			color: $brand-color-2;
		}

		&:last-child {
			border: 0;
		}
	}
}
