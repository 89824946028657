.test {
	x: true;
}

@mixin responsive($modes, $type: '') {
	$query: '';

	@if type-of($modes) == string and ($modes == 'min' or $modes == 'max') {
		@if ($modes == 'min') {
			$keys: ();
			$values: ();
			$width: 0;

			@each $key, $val in $responsive-max-resolutions {
				$keys: append($keys, $key);
				$values: append($values, $val);
			}

			@if index($keys, $type) < length($values) {
				$width: nth($values, (index($keys, $type) + 1));
			}

			$query: '(min-width: #{$width}px)';
		} @else {
			$query: '(max-width: #{(map-get($responsive-max-resolutions, $type) - 1)}px)';
		}
	} @else {
		@if type-of($modes) == string {
			$modes: ($modes);
		}

		@for $i from 1 through length($modes) {
			$mode: nth($modes, $i);
			@if not map-has-key($responsive-modes, $mode) {
				@error 'unsupported responsive mode';
			}
			@if $i == 1 {
				$query: map-get($responsive-modes, $mode);
			} @else {
				$query: '#{$query}, #{map-get($responsive-modes, $mode)}';
			}
		}
	}

	@media #{$query} {
		@content;
	}
}

@mixin replacement($block, $checked) {
	user-select: none;

	@if not $checked {
		&__input {
			display: none;
		}

		&__replacement {
			cursor: pointer;
			display: inline-block;
			position: relative;
			@content;
		}
	} @else {
		&__input:checked + .#{$block}__replacement {
			@content;
		}
	}
}

@mixin spacing(
	$max-sizes: 3,
	$directions: (
		'left',
		'right',
		'top',
		'bottom',
	)
) {
	&--spacing {
		margin-top: $block-spacing;
		margin-bottom: $block-spacing;

		@each $position in $directions {
			&-#{$position} {
				margin-#{$position}: $block-spacing;
			}
		}

		@for $i from 2 through $max-sizes {
			&-#{$i}x {
				margin-top: $block-spacing * $i;
				margin-bottom: $block-spacing * $i;
			}

			@each $position in $directions {
				&-#{$position}-#{$i}x {
					margin-#{$position}: $block-spacing * $i;
				}
			}
		}
	}
}

@mixin gutter(
	$max-sizes: 3,
	$directions: (
		'left',
		'right',
		'top',
		'bottom',
	)
) {
	&--gutter {
		padding-left: $block-spacing;
		padding-right: $block-spacing;

		@each $position in $directions {
			&-#{$position} {
				padding-#{$position}: $block-spacing;
			}
		}

		@for $i from 2 through $max-sizes {
			&-#{$i}x {
				padding-left: $block-spacing * $i;
				padding-right: $block-spacing * $i;
			}

			@each $position in $directions {
				&-#{$position}-#{$i}x {
					padding-#{$position}: $block-spacing * $i;
				}
			}
		}
	}
}

@mixin grid-area($grid-area, $order) {
	@if ($order != null) {
		$y-list: [];
		$x-list: [];

		@for $y from 1 through length($order) {
			$line: nth($order, $y);

			@if type-of($line) == list {
				@for $x from 1 through length($line) {
					$value: nth($line, $x);
					@if ($value == $grid-area) {
						$y-list: append($y-list, $y);
						$x-list: append($x-list, $x);
					}
				}
			} @else if ($line == $grid-area) {
				$y-list: append($y-list, $y);
				$x-list: append($x-list, 1);
			}
		}

		@if (length($x-list) > 0 and length($y-list) > 0) {
			$col-start: min($x-list...);
			$col-end: max($x-list...);
			$row-start: min($y-list...);
			$row-end: max($y-list...);

			display: block;
			grid-area: $grid-area;
			-ms-grid-column-span: $col-end - $col-start;
			-ms-grid-row-span: $row-end - $row-start;
			grid-column: #{$col-start}/#{$col-end};
			grid-row: #{$row-start}/#{$row-end};
		}
	}
}

@mixin grid($order) {
	$grid-elements: ();

	@each $row in $order {
		@each $col in $row {
			@if not index($grid-elements, $col) {
				$grid-elements: append($grid-elements, $col);
			}
		}
	}

	@each $element in $grid-elements {
		& > *[data-grid='#{$element}'] {
			@include grid-area($element, $order);
		}
	}
}

@mixin touch-device() {
	@media (hover: none) and (pointer: coarse) {
		@content;
	}
}

@mixin non-touch-device() {
	@media (hover: hover) and (pointer: fine) {
		@content;
	}
}

@mixin hide {
	@each $name, $media in $grid-responsive-modes {
		&--#{$name}-hidden {
			@media #{$media} {
				display: none;
			}
		}
	}
}
