.section {
	border: 1px solid $border-color;
	border-radius: 16px;
	display: flex;
	align-items: stretch;
	flex-flow: row wrap;
	overflow: hidden;

	&__image {
		width: 50%;
		min-height: 100%;
		background-size: cover;
		background-position: center;
		height: 577px;
	}

	&__content {
		width: 50%;
		min-height: 100%;
		display: flex;
		flex-flow: column wrap;
		justify-content: center;
		padding: 72px;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__image,
		&__content {
			width: 100%;
		}
	}

	@include responsive(('mobile')) {
		&__content {
			padding: $block-spacing * 4;
		}
	}
}
