@mixin message {
	@at-root .message {
		width: 100%;
		position: relative;
		display: none;
		line-height: 1.5em;
		padding: $block-spacing;

		&__text {
			width: calc(100% - 28px);
		}

		&__close {
			position: absolute;
			right: $block-spacing * 2;
		}

		&--error {
			color: $error-color;
			border: 1px solid $error-color;
			background-color: rgba($error-color, 0.2);
		}

		&--success {
			color: $success-color;
			border: 1px solid $success-color;
			background-color: rgba($success-color, 0.2);
		}

		&--visible {
			display: flex;
			align-items: center;
		}

		a {
			text-decoration: underline;
		}

		@content;
	}
}
