@mixin terms-and-conditions {
	@at-root .terms-and-conditions {
		@extend %flex-row;

		width: 50%;
		padding: $block-spacing calc(#{$block-spacing} / 2);

		&__item {
			color: $font-color;
			padding: 0 calc(#{$block-spacing} / 2);

			&:hover {
				text-decoration: underline;
			}
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			width: 100%;
			justify-content: center;
		}

		@content;
	}
}
