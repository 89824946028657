@mixin logo {
	@at-root .logo {
		position: relative;
		display: block;
		height: 50px;
		width: 160px;
		margin: $block-spacing;

		&__image {
			@extend %position-center;

			display: block;
			max-height: 100%;
			max-width: 100%;
		}

		&--header {
			height: 80px;
			width: 220px;
			margin: 0 $block-spacing;
		}

		&--line {
			height: auto;
			width: 100%;
			max-width: 100%;
			display: flex;
			align-items: center;
			justify-content: center;
			margin: 0;
			user-select: none;
			margin-bottom: $block-spacing * 2;

			.logo__image {
				display: inline-block;
				position: unset;
				transform: unset;
				top: auto;
				left: auto;
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
			&--header {
				height: 44px;
				width: 113px;
			}
		}

		@include responsive(('mobile')) {
			&--header {
				width: 100px;
				margin-left: 0;
				margin-right: 0;
			}
		}

		@content;
	}
}
