.fit-indicator {
	width: 100%;
	position: relative;
	padding-top: 10px;

	&__h-bar {
		width: 100%;
		height: 1px;
		background-color: $brand-color-2;
		margin-bottom: 10px;
	}

	&__v-bar {
		width: 2px;
		height: 20px;
		background-color: $black;
		position: absolute;
		top: 0;
	}

	&__container {
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 8px;
		margin-bottom: $block-spacing * 2;
	}

	&__item {
		font-size: 12px;
		text-transform: uppercase;
		font-weight: bold;
		color: $black;
		flex: 1;
		text-align: center;

		&:first-child {
			text-align: left;
		}

		&:last-child {
			text-align: right;
		}
	}
}
