.size-select {
	width: 100%;
	position: relative;

	&__dropdown {
		appearance: none;
		width: 100%;
		height: 50px;
		border: 1px solid $border-color;
		border-radius: 500px;
		color: $font-color;
		font-family: $primary-font-family;
		font-size: 14px;
		text-transform: uppercase;
		padding: 0 $block-spacing * 2;
		margin-bottom: $block-spacing * 2;
		background-image: url('#{$image-path}/icons/angle-down.png');
		background-repeat: no-repeat;
		background-position: center right $block-spacing * 2;
		background-size: 12px;
		padding-right: 144px;
		font-weight: bold;

		&:hover {
			border: 1px solid $brand-color;
			cursor: pointer;
		}
	}

	&__stock {
		display: flex;
		align-items: center;
		justify-content: center;
		position: absolute;
		top: 0;
		height: 50px;
		user-select: none;
		pointer-events: none;
		right: 44px;
		color: $font-color;
		font-size: 14px;
		font-style: italic;
	}

	&--hidden {
		display: none;
	}
}
