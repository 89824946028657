@mixin number {
	@at-root .number {
		@extend %flex-row;

		align-items: center;

		&__button {
			@extend %paragraph;

			cursor: pointer;
			display: inline-block;
			position: relative;
			width: 46px;
			height: 46px;
			line-height: 46px;
			text-align: center;
			background: $background-color;
			border: 1px solid $border-color;

			i {
				line-height: inherit;
			}

			&:hover {
				background: $background-color;
			}

			&:first-child {
				border-right: 0;
			}

			&:last-child {
				border-left: 0;
			}
		}

		&__input {
			@extend %input;
			@extend %paragraph;

			border: 0;
			border: 1px solid $border-color;
			cursor: pointer;
			height: 46px;
			width: 46px;
			background-color: $white;
			text-align: center;

			&::-webkit-outer-spin-button,
			&::-webkit-inner-spin-button {
				appearance: none;
				margin: 0;
			}
		}

		@content;
	}
}
