@include checkbox {
	@extend %flex-row;

	position: relative;
	font-size: 14px;
	color: $black;
	align-items: flex-start;
	font-family: $primary-font-family;

	&__replacement {
		border-color: $font-color;
		border-radius: 0;
		margin: 0 $block-spacing 0 0;

		&--button {
			min-width: auto;
			width: auto;
			height: auto;
			padding: $block-spacing * 2;
			font-size: 12px;
			text-transform: uppercase;
			color: $black;
			background-color: $white;
			border: 1px solid $black;
			font-weight: bold;
			white-space: nowrap;
		}
	}

	&--shoppingcart {
		$padding: ($block-spacing * 2) - 1px;

		padding: $padding;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: flex-start;
		gap: $block-spacing;

		.checkbox__replacement {
			margin: 0;
		}

		.checkbox__input {
			left: $padding;
		}
	}

	&__input:checked + .checkbox__replacement {
		border-color: $font-color;

		&--button {
			color: $white;
			background-color: $black;
		}
	}

	&__input {
		display: unset;
		position: absolute;
		opacity: 0;
		z-index: -1;
		left: 1px;
	}

	&__label {
		flex: 1;
	}

	a {
		text-decoration: underline;
	}

	&--filters {
		width: 100%;
		padding: $block-spacing 0;
	}

	&--account {
		display: block;
	}
}

.shoppingcart-total + .checkbox {
	margin-bottom: $block-spacing * 2;
}
