.parcelshops {
	&__form {
		&--hidden {
			pointer-events: none;
			display: none;
		}
	}

	&__loader {
		position: absolute;
		right: 14px;
		top: 38%;
		background: $white;

		&--hidden {
			display: none !important;
		}
	}
}
