@mixin media() {
	@at-root .media {
		position: relative;
		width: 100%;
		display: block;
		background-size: cover;
		background-repeat: no-repeat;
		background-position: center center;

		&__image {
			display: block;
			width: 100%;
		}

		&__caption {
			@extend %flex-column;
			@extend %position-center;

			align-items: center;
			justify-content: center;
			color: $font-color;
			text-align: center;

			&--light {
				color: $white;
			}
		}

		&__title {
			@extend %heading2;

			color: inherit;
		}

		&__text {
			@extend %paragraph;
		}

		&--video {
			iframe {
				@extend %position-top;

				width: 100%;
				height: 100%;
			}

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 56.25%;
			}
		}

		&--square-video {
			overflow: hidden;

			iframe {
				@extend %position-top;

				width: 200%;
				height: 100%;
			}

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}

		&--square {
			width: 100%;
			height: auto;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}

		@content;
	}
}
