.sitemap {
	color: $font-color;
	width: 16.6666%;
	padding: $block-spacing;

	&__title {
		font-family: $secondary-font-family;
		font-size: 18px;
		margin-bottom: $block-spacing * 2;
		font-weight: 700;
		display: block;
	}

	&__item {
		font-size: 16px;
		font-weight: 400;
		line-height: 1.5em;
		display: block;
		padding: $block-spacing 0;

		&--text {
			font-size: 14px;
			font-weight: 700;
			line-height: 40px;
		}
	}

	&--mobile {
		display: none;
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
		border-bottom: 1px solid $border-color;
		margin: 0;
		padding: $block-spacing 0;

		&__title {
			margin: $block-spacing 0;
			padding-right: $block-spacing * 2;
			font-size: 16px;
		}

		&__item {
			font-size: 14px;
			padding: $block-spacing;
		}

		i {
			font-size: 18px;
		}

		&--desktop {
			display: none;
		}

		&--mobile {
			display: block;
		}
	}
}
