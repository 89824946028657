@include terms-and-conditions {
	align-items: center;
	justify-content: center;

	&__item {
		font-size: 16px;
		line-height: 1.5em;
		color: $brand-color-2;
		position: relative;
		padding: 0;

		&::after {
			content: '/';
			color: $font-color;
			font-size: 16px;
			padding: 0 $block-spacing;
		}

		&:last-child {
			&::after {
				display: none;
			}
		}
	}

	a {
		&:hover {
			text-decoration: underline;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
		width: 100%;
	}
}
