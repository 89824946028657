.stock-status {
	font-size: 14px;
	color: $brand-color-2;
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	margin-bottom: $block-spacing * 2;
	gap: $block-spacing;

	i {
		font-size: 12px;
	}

	i.stock-status__loader {
		display: none;
	}

	&--success {
		color: $success-color;

		.stock-status__icon {
			color: $success-color;
		}

		.stock-status__arrow {
			fill: $success-color;
		}
	}

	&--warning {
		color: $warning-color;

		.stock-status__icon {
			color: $warning-color;
		}

		.stock-status__arrow {
			fill: $warning-color;
		}
	}

	&--error {
		color: $error-color;

		.stock-status__icon {
			color: $error-color;
		}

		.stock-status__arrow {
			fill: $error-color;
		}
	}
}
