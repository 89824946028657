.shoppingcart-shipping {
	&__label {
		margin-bottom: $block-spacing * 2;

		&--disabled {
			pointer-events: none;
			opacity: 0.5;
		}
	}

	&__message {
		padding: $block-spacing * 2;
		background-color: rgb(255 223 99 / 12%);
		line-height: 24px;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='8' ry='8' stroke='%23FFDF63FF' stroke-width='4' stroke-dasharray='8%2c 16' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
		border-radius: 8px;

		a {
			font-weight: 600;
			text-decoration: underline;
		}

		&--hidden {
			display: none;
		}
	}

	&__table {
		display: flex;
		flex-direction: row;
		margin-top: $block-spacing * 4;
	}

	&__col {
		&--first {
			margin-right: $block-spacing * 5;
			border-right: 1px solid $brand-color;
			padding-right: $block-spacing * 5;

			@include responsive('mobile') {
				margin-right: $block-spacing * 2;
				padding-right: $block-spacing * 2;
			}
		}

		&--caption {
			margin-bottom: $block-spacing * 2;
		}
	}
}
