@mixin filters() {
	@at-root .filters {
		position: relative;

		&__item {
			@extend %paragraph;

			display: block;
			position: relative;

			&:hover {
				color: $hover-color;
			}

			&--active {
				color: $active-color;
			}

			&--category {
				@extend %heading6;

				font-weight: bold;
			}
		}

		@content;
	}
}
