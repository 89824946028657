@include size {
	margin: 0 $block-spacing $block-spacing 0;

	&__replacement {
		min-width: 50px;
		color: $black;
		font-size: 16px;
		font-weight: bold;
		padding: $block-spacing * 1.5 $block-spacing / 2;
		border: 1px solid $black;
		background-color: $white;
		font-family: $primary-font-family;
		margin: 0;
	}

	&--filters {
		flex: 1;
		display: flex;
		margin: $block-spacing / 2 $block-spacing $block-spacing / 2 0;

		.size__replacement {
			flex: 1;
			white-space: nowrap;
		}
	}

	&__input:checked + .size__replacement {
		background-color: $black;
	}
}
