.product-specs {
	margin-top: $block-spacing * 2;
	background-color: $white;
	color: $black;
	font-size: 18px;

	&__heading {
		border-top: 1px solid $background-color;
		padding: $block-spacing * 4 $block-spacing * 5 $block-spacing * 4 $block-spacing * 4;
		font-weight: bold;
		font-family: $secondary-font-family;
	}

	&__content {
		@extend %paragraph;

		padding: 0 $block-spacing * 4 $block-spacing * 4 $block-spacing * 4;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			padding-right: 8.3333%;
		}
	}

	.accordion__icon-open,
	.accordion__icon-close {
		right: $block-spacing * 4;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			right: 8.3333%;
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&__heading {
			padding-left: 8.3333%;
		}
	}
}
