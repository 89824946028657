@include button {
	font-size: 16px;
	font-weight: 600;
	padding: $block-spacing * 1.5 $block-spacing * 3;
	border-radius: 50px;

	&--hidden {
		display: none !important;
	}

	&--loading {
		position: relative;
		display: flex;
		justify-content: center;

		:not(.button__loader) {
			visibility: hidden;
		}
	}

	&--disabled {
		pointer-events: none;
	}

	&:disabled,
	&--disabled {
		background: $background-color;
		border: $background-color;
		color: $font-color;
		font-weight: 500;

		&:hover {
			background: $background-color;
			border: $background-color;
			cursor: not-allowed;
		}
	}

	.button__loader {
		--icon-width: 20px;

		position: absolute;
		left: calc(50% - calc(var(--icon-width) / 2));
	}

	&--1 {
		background-color: $highlight-color;
		border-bottom: 1px solid $highlight-border;
		color: $black;

		&:hover {
			background-color: $highlight-border;
		}
	}

	&--2 {
		background-color: $brand-color-2;
		border-bottom: 1px solid $brand-color;
		color: $white;

		&:hover {
			background-color: $brand-color;
		}
	}

	&--3 {
		&:hover {
			text-decoration: underline;
		}
	}

	&--link {
		background-color: transparent;
		border-bottom: 1px solid transparent;
		color: $font-color;
		text-decoration: underline;
		padding-left: 0;
		padding-right: 0;
	}

	&--round {
		padding: 0;
		height: 50px;
		width: 50px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 18px;
	}

	&--icon {
		background-color: transparent;
		padding: 0;
		font-weight: 700;
		display: flex;
		flex-flow: row nowrap;
		gap: $block-spacing * 2;
		align-items: center;

		.button__icon {
			color: $white;
			background-color: $brand-color;
			display: flex;
			align-items: center;
			justify-content: center;
			font-size: 14px;
			height: 34px;
			width: 34px;
			border-radius: 34px;
			transition: background-color 0.3s ease-in-out 0s;

			&--highlight {
				background-color: $highlight-color;
				color: $font-color;
			}
		}

		.button__content {
			opacity: 0;
			transition: opacity 0.3s ease-in-out 0s;
			color: $font-color;

			&--white {
				color: $white;
			}
		}

		&:hover {
			.button__icon {
				background-color: $brand-color-2;

				&--highlight {
					color: $white;
				}
			}

			.button__content {
				opacity: 1;
			}
		}
	}

	&--white {
		color: $font-color;
		background-color: $white;
		border-bottom: 1px solid $brand-color-2;

		&:hover {
			background-color: $brand-color-2;
		}
	}

	&--position-right {
		right: $block-spacing;
	}

	&--spacing {
		margin-top: $block-spacing * 4;
		margin-bottom: $block-spacing * 4;
	}

	&--spacing-top {
		margin-top: $block-spacing * 4;
	}

	&--spacing-bottom {
		margin-bottom: $block-spacing * 4;
	}

	&--space-between {
		justify-content: space-between;
		display: flex;
		align-items: center;
		text-align: left;
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
		&--mobile-hidden {
			display: none;
		}

		&--full-width-mobile {
			width: 100%;
		}

		&--position-mobile {
			position: unset;
			right: auto;
			top: auto;
			bottom: auto;
			left: auto;
			transform: none;
			margin-left: calc(8.3333% + #{$block-spacing});
			margin-right: calc(8.3333% + #{$block-spacing});
			margin-top: $block-spacing * 3;
			width: calc(100% - #{$block-spacing * 2});
		}

		&--spacing {
			margin-top: $block-spacing * 2;
			margin-bottom: $block-spacing * 2;
		}

		&--spacing-top {
			margin-top: $block-spacing * 2;
		}

		&--spacing-bottom {
			margin-bottom: $block-spacing * 2;
		}
	}
}
