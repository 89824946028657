.popup {
	@extend %flex-row;

	flex-wrap: nowrap;
	z-index: 9;
	align-items: stretch;
	justify-content: center;
	position: fixed;
	left: 50%;
	top: 50%;
	transform: translate(-50%, -50%);
	background-color: $white;
	max-width: calc(100% - #{$block-spacing * 4});
	// fallback for "dvh" usage
	max-height: calc(95vh - #{$block-spacing * 4});
	/* stylelint-disable */
	max-height: calc(100dvh - #{$block-spacing * 4});
	/* stylelint-enable */
	border: 1px solid $border-color;
	border-radius: 8px;
	overflow: hidden;

	&__left {
		width: 500px;
		background-position: center;
		background-image: url('/_dekroo/img/newsletter-bg.png');
		background-size: cover;
		background-repeat: no-repeat;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}

	&__right {
		width: 375px;
		display: flex;
		flex-flow: column wrap;
	}

	&__scroll {
		overflow-y: auto;
		flex: 1;
		padding: $block-spacing * 3;
	}

	&__close {
		position: absolute;
		top: 0;
		right: 0;
		padding: $block-spacing * 2;
		color: $font-color;
		font-size: 18px;
		cursor: pointer;
	}

	&__container {
		@extend %flex-row;

		align-items: center;
		justify-content: space-between;
		gap: $block-spacing * 2;

		.mdc-text-field {
			&:last-child {
				width: 100%;
			}
		}

		&--hidden {
			display: none !important;
		}
	}

	.button {
		margin-top: $block-spacing;
	}

	&--hidden {
		display: none;
	}
}
