.banners {
	display: flex;
	flex-flow: row wrap;
	align-items: stretch;
	position: relative;

	&__small {
		display: flex;
		flex-flow: row wrap;
		align-items: stretch;
		width: calc(50% - #{$block-spacing * 2});
		margin: 8px;
		border-radius: 8px;
		overflow: hidden;

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
			width: calc(100% - #{$block-spacing * 2});
		}

		.title {
			margin-bottom: $block-spacing;
		}

		.media {
			width: 300px;

			@include responsive(('mobile', 'mobile-landscape')) {
				width: 100%;
			}
		}

		.banners__content {
			width: calc(100% - 300px);
			background-color: rgba($background-color-3, 0.15);
			padding: $block-spacing * 4;
			flex: 1 1 auto;
			display: flex;
			gap: $block-spacing;
			flex-flow: column wrap;
			justify-content: space-between;

			@include responsive(('mobile', 'mobile-landscape')) {
				width: 100%;
			}
		}
	}

	&__col {
		display: flex;
		flex-flow: column wrap;
		width: 25%;
		border-right: 1px solid $border-color;
		border-bottom: 1px solid $border-color;
		padding: $block-spacing * 3 $block-spacing * 6;

		.banners__content {
			flex: 1 1 auto;
			display: flex;
			flex-flow: column wrap;
			justify-content: space-between;
		}

		.media {
			margin-bottom: $block-spacing * 2;
		}

		.title {
			margin-bottom: $block-spacing;
		}

		.text {
			margin-bottom: $block-spacing * 2;
		}
	}

	&--small {
		padding-top: $block-spacing * 2;
	}

	@include responsive(('desktop-hd')) {
		&__col {
			&:nth-child(4n + 4) {
				border-right: 1px solid transparent;
			}

			&:nth-last-child(-n + 4) {
				border-bottom: 1px solid transparent;
			}
		}
	}

	@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape', 'desktop')) {
		&__col {
			padding: $block-spacing * 3;
		}
	}

	@include responsive(('mobile-landscape', 'tablet')) {
		&__col {
			width: 50%;

			&:nth-child(2n + 2) {
				border-right: 1px solid transparent;
			}

			&:nth-last-child(-n + 2) {
				border-bottom: 1px solid transparent;
			}
		}
	}

	@include responsive(('mobile')) {
		&__col {
			width: 100%;
			border: 0;
		}
	}
}
