.set {
	display: block;
	width: 100%;

	&__image {
		display: block;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		border-radius: 8px;
		overflow: hidden;
		margin-bottom: $block-spacing;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 150%;
		}
	}

	&__name {
		color: $font-color;
		font-family: $primary-font-family;
		font-size: 16px;
		margin-top: $block-spacing;
	}
}
