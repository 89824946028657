.appointments {
	display: flex;
	flex-flow: column wrap;
	gap: $block-spacing * 2;

	&__item {
		display: flex;
		flex-flow: row wrap;
		min-height: 70px;
		align-items: stretch;
		background-color: $white;
	}

	&__date {
		color: $white;
		background-color: $background-color-3;
		font-weight: bold;
		width: 110px;
		display: flex;
		align-items: center;
		justify-content: center;
		gap: $block-spacing;
		padding: 0 $block-spacing * 2;

		&:nth-child(2) {
			border-left: 1px solid $white;
		}
	}

	&__tm {
		font-size: 10px;
	}

	&__day {
		font-size: 40px;
	}

	&__month {
		font-size: 12px;
	}

	&__title {
		text-transform: uppercase;
		color: $font-color;
		font-size: 16px;
		font-weight: bold;
		flex: 1;
		display: flex;
		flex-flow: row nowrap;
		align-items: center;
		gap: $block-spacing;
		justify-content: flex-end;
		padding: $block-spacing 8% $block-spacing $block-spacing;
	}

	@include responsive(('mobile', 'mobile-landscape')) {
		&__item {
			justify-content: space-between;
			align-items: flex-start;
			min-height: unset;
		}

		&__date {
			padding: $block-spacing;
		}

		&__title {
			width: 100%;
			flex: 0 1 auto;
			justify-content: space-between;
			padding: $block-spacing * 2;
		}

		&__day {
			font-size: 30px;
		}
	}
}
