@mixin tabs {
	@at-root .tabs {
		width: 100%;

		&__container {
			display: flex;
			flex-flow: row wrap;
			gap: $block-spacing * 3;
		}

		&__tab {
			position: relative;
			cursor: pointer;
			padding-bottom: $block-spacing;
			border-bottom: 2px solid transparent;
			font-size: 16px;
			line-height: normal;
			letter-spacing: -0.32px;
			font-weight: 400;
			text-transform: uppercase;

			&--active,
			&:hover {
				border-bottom: 2px solid $font-color;
			}

			&--active {
				font-weight: 700;
			}

			&--no-hover,
			&--no-hover:hover {
				border-bottom: 2px solid transparent;
				cursor: unset;
			}

			i {
				color: $highlight-color;
			}
		}

		&__content {
			display: none;
			padding-top: $block-spacing * 4;

			&--active {
				display: block;
			}
		}
		@content;
	}
}
