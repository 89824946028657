@include media {
	&__caption {
		width: 100%;

		&--dark {
			color: $black;
		}
	}

	&__title {
		font-size: 40px;
		font-weight: 700;
		line-height: normal;
		text-transform: uppercase;
		font-family: $secondary-font-family;
		width: 83.3333%;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			font-size: 24px;
		}
	}

	&--hero {
		background-position: center right;
		height: calc(100vh - 98px);
		min-height: 830px;
	}

	&--hero-video {
		overflow: hidden;
		height: 500px;

		iframe {
			@extend %position-top;

			width: 210%;
			height: 100%;
		}

		@include responsive(('mobile')) {
			height: 260px;
			min-height: 260px;
		}
	}

	&--hero-home {
		min-height: 500px;

		@include responsive(('mobile')) {
			height: 260px;
			min-height: 260px;
		}
	}

	&--hero-landscape {
		background-position: center top;
		height: 500px;
	}

	&--hero-category {
		background-position: center;
		height: 300px;
		border-radius: 16px;
		overflow: hidden;
	}

	&--50 {
		overflow: hidden;
		border-radius: 8px;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 50%;
		}
	}

	&--65 {
		&::after {
			content: ' ';
			display: block;
			padding-bottom: 65%;
		}
	}

	&--85 {
		overflow: hidden;
		border-radius: 8px;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 85%;
		}
	}

	&--120 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 120%;
		}
	}

	&--145 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 145%;
		}
	}

	&--150 {
		min-height: 100%;

		&::after {
			content: ' ';
			display: block;
			padding-bottom: 150%;
		}
	}

	&--spacing-bottom-4x {
		margin-bottom: $block-spacing * 4;
	}

	&--radius {
		border-radius: 16px;
		overflow: hidden;
	}

	&--shop {
		height: calc(100% - 67px);

		@include responsive(('tablet')) {
			height: calc(100% - 24px);
		}

		@include responsive(('mobile', 'mobile-landscape')) {
			height: auto;

			&::after {
				content: ' ';
				display: block;
				padding-bottom: 100%;
			}
		}
	}

	&--brand {
		position: absolute;
		bottom: 0;
		right: 0;
		width: calc(41.67% + #{$block-spacing + 89px});
		height: 295px;
		background-position: center right;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			bottom: auto;
			right: auto;
			position: static;
			order: 1;
			width: 100%;
			margin-top: $block-spacing * 2;
		}
	}

	&--brand-logo {
		position: absolute;
		top: $block-spacing * 2 + 1px;
		right: 1px;
		height: 97px;
		width: 97px;
		background-size: 80%;
		background-color: $white;
		border-radius: 16px;

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			top: auto;
			bottom: 100%;
			right: 0;
			border: 0;
			border-radius: 0;
		}
	}

	&__buttons {
		@extend %flex-row;

		margin: 0 $block-spacing * 3;
		max-width: 600px;
		padding-top: $block-spacing;
		width: 83.3333%;

		.button {
			width: calc(50% - #{$block-spacing * 2});
			margin: $block-spacing;
		}

		&--center {
			justify-content: center;
		}

		@include responsive(('mobile')) {
			width: 100%;
			margin: 0;
			padding: 5% $block-spacing 0;
		}
	}

	&--tag-image {
		height: 100%;
		position: relative;

		.media__image {
			position: absolute;
			bottom: -27px;
			left: 0;
			width: 163%;

			@include responsive(('tablet-landscape')) {
				bottom: -8px;
				width: 165%;
			}
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
			display: none;
		}
	}
}
