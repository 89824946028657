@function column-width($column) {
	@return 100 / 12 * $column * 1%;
}

@mixin grid {
	@at-root .grid {
		@extend %flex-row;

		position: relative;
		width: $grid-width;
		max-width: 100%;
		margin: 0 auto;
		box-sizing: border-box;

		&__col {
			position: relative;
			padding: $grid-column-vertical-spacing $grid-column-horizontal-spacing;
			box-sizing: border-box;
			flex: 0 1 auto;

			@for $column from 1 to 13 {
				&--#{$column} {
					width: #{column-width($column)};
				}
				&--order-#{$column} {
					order: #{$column};
				}
			}

			@each $breakpoint in map-keys($grid-responsive-modes) {
				@for $column from 1 to 13 {
					&--#{$breakpoint}-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							width: #{column-width($column)};
						}
					}
					&--#{$breakpoint}-order-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							order: #{$column};
						}
					}
				}
			}

			@for $column from 1 to 12 {
				&--offset-#{$column} {
					margin-left: column-width($column);
				}
			}

			@each $breakpoint in map-keys($grid-responsive-modes) {
				@for $column from 1 to 12 {
					&--offset-#{$breakpoint}-#{$column} {
						@media #{map-get($grid-responsive-modes, $breakpoint)} {
							margin-left: column-width($column);
						}
					}
				}
				&--no-offset-#{$breakpoint} {
					@media #{map-get($grid-responsive-modes, $breakpoint)} {
						margin-left: 0;
					}
				}
			}

			&--no-spacing {
				padding: 0;
			}

			&--vertical-spacing {
				padding-top: $vertical-spacing + $block-spacing;
				padding-bottom: $vertical-spacing + $block-spacing;
			}

			&--vertical-spacing-top {
				padding-top: $vertical-spacing + $block-spacing;
			}

			&--vertical-spacing-bottom {
				padding-bottom: $vertical-spacing + $block-spacing;
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
				&--vertical-spacing {
					padding-top: $vertical-spacing-mobile + $block-spacing;
					padding-bottom: $vertical-spacing-mobile + $block-spacing;
				}

				&--vertical-spacing-top {
					padding-top: $vertical-spacing-mobile + $block-spacing;
				}

				&--vertical-spacing-bottom {
					padding-bottom: $vertical-spacing-mobile + $block-spacing;
				}

				&--hidden-tablet-landscape {
					display: none;
				}
			}

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				&--no-spacing-tablet {
					padding: 0;
				}

				&--hidden-tablet {
					display: none;
				}
			}
		}

		&--spacing {
			padding: 0 $grid-spacing;
		}

		&--full-width {
			width: 100%;
			// max-width: calc(#{$grid-width * 2} - 2px);
		}

		&--justify-center {
			justify-content: center;
		}

		&--space-evenly {
			justify-content: space-evenly;
		}

		&--space-around {
			justify-content: space-around;
		}

		&--space-between {
			justify-content: space-between;
		}

		&--end {
			justify-content: flex-end;
		}

		&--flex-end {
			align-items: flex-end;
		}

		&--center {
			align-items: center;
		}

		&--bottom {
			align-items: flex-end;
		}

		&--stretch {
			align-items: stretch;
		}

		&--baseline {
			align-items: baseline;
		}

		&--vertical-spacing {
			padding-top: $vertical-spacing;
			padding-bottom: $vertical-spacing;
		}

		&--vertical-spacing-top {
			padding-top: $vertical-spacing;
		}

		&--vertical-spacing-bottom {
			padding-bottom: $vertical-spacing;
		}

		@include responsive(('tablet-landscape', 'desktop', 'desktop-hd')) {
			&--vertical-spacing-bottom-desktop {
				padding-bottom: $vertical-spacing;
			}
		}

		&--vertical-spacing-half {
			padding-top: $vertical-spacing / 2;
			padding-bottom: $vertical-spacing / 2;
		}

		&--vertical-spacing-half-top {
			padding-top: $vertical-spacing / 2;
		}

		&--vertical-spacing-half-bottom {
			padding-bottom: $vertical-spacing / 2;
		}

		&--horizontal-spacing {
			padding-left: $horizontal-spacing;
			padding-right: $horizontal-spacing;
		}

		&--full-height {
			height: 100%;
		}

		&--max-width {
			max-width: 90%;
		}

		&--below-hero {
			margin-top: -$vertical-spacing;

			@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
				margin-top: -$vertical-spacing-mobile;
			}
		}

		&--showroom-banner {
			height: 100%;

			@include responsive(('mobile', 'mobile-landscape', 'tablet')) {
				height: auto;
			}
		}

		&--background,
		&--background-1 {
			background-color: $background-color;
		}

		&--background-2 {
			background-color: $background-color-2;
		}

		&--background-3 {
			background-color: $background-color-3;
		}

		&--background-4 {
			background-color: $background-color-4;
		}

		@include responsive(('mobile', 'mobile-landscape', 'tablet', 'tablet-landscape')) {
			&--vertical-spacing {
				padding-top: $vertical-spacing-mobile;
				padding-bottom: $vertical-spacing-mobile;
			}

			&--vertical-spacing-top {
				padding-top: $vertical-spacing-mobile;
			}

			&--vertical-spacing-bottom {
				padding-bottom: $vertical-spacing-mobile;
			}

			&--vertical-spacing-half {
				padding-top: $vertical-spacing-mobile / 2;
				padding-bottom: $vertical-spacing-mobile / 2;
			}

			&--vertical-spacing-half-top {
				padding-top: $vertical-spacing-mobile / 2;
			}

			&--vertical-spacing-half-bottom {
				padding-bottom: $vertical-spacing-mobile / 2;
			}

			&--no-vertical-spacing-bottom-tablet-landscape {
				padding-bottom: 0;
			}

			&--horizontal-spacing {
				padding-left: $horizontal-spacing-mobile;
				padding-right: $horizontal-spacing-mobile;
			}
		}

		@content;
	}
}
