@mixin search() {
	@at-root .search {
		position: relative;
		flex-grow: 1;

		&__input {
			@extend %input;

			padding: $block-spacing;

			&--error {
				border-color: $error-color;
			}

			&:focus {
				border-color: $active-color;
			}
		}

		&__dropdown {
			position: absolute;
			top: 100%;
			left: 0;
			width: 100%;
			border: 1px solid $border-color;
			background-color: $white;
			z-index: 1;
			display: none;
			line-height: 1.5em;

			&__results {
				padding: $block-spacing;
				position: relative;
			}

			&__result {
				display: block;
				cursor: pointer;
			}

			&--active {
				display: block;
			}
		}

		@content;
	}
}
