.cc_div .cc-link {
	color: $black;
	border-color: $black;
}

.cc_div .c-bn {
	text-transform: uppercase;
	border-radius: 0;
	background-color: $background-color;
	color: $black;

	&:hover {
		background-color: rgba($background-color, 0.8) !important;
		text-decoration: underline;
	}
}

@include responsive(('desktop', 'desktop-hd')) {
	.cc_div .bar #s-bns {
		display: flex;
		height: fit-content;
	}
}

#c-bns button:first-child,
#s-bns button:first-child {
	background-color: $black;

	&:hover {
		background-color: rgba($black, 0.8) !important;
	}
}
